<ng-container>

    <div class="row">
        <div class="col-12">
            <fieldset class="custom-fieldset mb-2">
                <legend> Info patient </legend>

                <div class="col-12">
                    <app-info-patient-consultation
                        [idPatient]="currentSeance?.dataProgramme?.dataRequete?.dataBeneficiaire?.id"
                        [withAssurances]="false" (patientSelected)="itemPatientSelected($event)"
                        [hassNeedGroupSanguin]="true">
                    </app-info-patient-consultation>
                </div>
            </fieldset>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 col-sm-12">
            <div class="col-12 mb-3">
                <label>N°Séance Programme: </label>
                <input class="form-control" autocomplete="off" [(ngModel)]="currentSeance.code" name="numùero Seance"
                    [disabled]="true" required type="text" />
            </div>

            <div class="col-12 mb-3">
                <label>Observation : </label>
                <textarea rows="3" class="form-control custom-input" name="observation"
                    [(ngModel)]="itemToSave.observation" placeholder="Observation">
                </textarea>
            </div>
        </div>

        <div class="col-md-8 col-sm-12"  *ngIf="currentPatientInAccueilDialyse && currentPatientInAccueilDialyse?.categorie?.toLowerCase() != 'assure'">
            <div class="col-md-12">

                <div class="row">
                    <div class="col-md-5 col-sm-12 mb-2">
                        <label>Unité fonctionnelle :
                            <span class="required_champs">*</span>
                        </label>
                      
                        <ng-select
                        [(ngModel)]="itemToSave.uniteFonctionnelleSelected"
                        placeholder="Choisissez une unité"
                        name="uniteFonctionnelleSelected"
                        id="uniteFonctionnelleSelected"
                        [multiple]="false"
                        [closeOnSelect]="true"
                        (change)="getActes()"
                      >
                        <ng-option
                        *ngFor="let item of listeUniteFonctionnelle"
                        [value]="item"
                          >{{ item.libelle |uppercase}}</ng-option
                        >
                      </ng-select>

                    </div>
               
                    <div class="col-md-5 col-sm-12 mb-0">
                        <label>Actes :
                            <span class="required_champs">*</span>
                        </label>
                      
                        <ng-select
                        [(ngModel)]="itemToSave.acteSelected"
                        placeholder="Choisissez un acte"
                        name="acteSelected"
                        id="acteSelected"
                        [multiple]="false"
                        [closeOnSelect]="true"
                        (change)="addActes()"
                      >
                        <ng-option
                        *ngFor="let item of listActes"
                        [value]="item"
                          >{{ item.adminActeLibelle |uppercase}}</ng-option
                        >
                      </ng-select>

                    </div>

                    <div class="col-md-2 col-sm-12 mb-0">
                        <button class="btn btn-xs btn-default btn-add" type="button"
                            (click)="addActes()"> Ajouter </button>
                    </div>

                </div>

                <div class="row" *ngIf='listActesToSave && listActesToSave.length > 0'>
                    <div class="col-md-12">
                        <table class="table table-bordered table-condensed table-striped table-reception">
                            <thead>
                                <tr class="custom-table-head">
                                    <th style="text-align: center;">#</th>
                                    <th>Acte</th>
                                    <th style="width: 70px;">Qté / NB</th>
                                    <th>Prix U</th>
                                    <th>PC</th>
                                    <th>Montant</th>
                                    <th style="text-align:center;width:60px">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    *ngFor="let act of listActesToSave; let indice =  index">
                                    <td style="text-align: center;"> {{indice + 1}} </td>
                                    <td> {{act?.adminActeLibelle}} </td>
                                    <td>
                                        <input type="text" mask='separator' class="form-control"
                                            [(ngModel)]="act.quantiteActe" (keypress)="getTotalFacture()"
                                            (keyup)="getTotalFacture()"
                                            (change)="getTotalFacture()"
                                            style="width: 70px;" />
                                    </td>
                                    <td> {{act?.prixUnitaire | moneyFormat}} </td>
                                    <td>
                                        <input type="checkbox" [(ngModel)]="act.isPc" name="checkboxItem" [ngModelOptions]="{standalone: true}" />
                                    </td>
                                    <td>{{ (act?.prixUnitaire * act.quantiteActe) | moneyFormat}} </td>
                                    <td style="text-align: center;">
                                        <a title="Supprimer acte"
                                            (click)="deleteItemActe(indice)"
                                            style="color: red;">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="5" style="text-align: right;">
                                        <span> Coût Total </span>
                                    </td>
                                    <td>
                                        {{itemToSave?.totalFactureActe | moneyFormat}}
                                    </td>
                                    <td></td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                </div>
            </div>

        </div>
 
        <div class="col-md-8 col-sm-12"  *ngIf="currentPatientInAccueilDialyse && currentPatientInAccueilDialyse?.categorie?.toLowerCase() == 'assure'">
            <fieldset class="custom-fieldset mb-2">
                <legend> Assurances </legend>
                <div class="row">
                    <div class="col-md-8 col-sm-12 mb-0">
                        <label>Assurances :
                            <span class="required_champs">*</span>
                        </label>
                        <select class="form-select custom_input" id="assuranceSelected"
                            [(ngModel)]="itemToSave.assuranceSelected" (change)="addAssurance()"
                            name="assuranceSelected">
                            <option value="">-- Choisissez --</option>
                            <option *ngFor="let item of listAssurances" [disabled]="item.disabled" [ngValue]="item">
                                {{ item.raisonSociale }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4 col-sm-12 mb-0">
                        <button class="btn btn-xs btn-default btn-add" type="button" (click)="addAssurance()"> Ajouter
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-condensed table-striped table-reception">
                            <thead>
                                <tr class="custom-table-head">
                                    <th style="text-align: center;">#</th>
                                    <th>Assurance</th>
                                    <th>Taux</th>
                                    <th>N° Bon</th>
                                    <th>N°Carte</th>
                                    <th style="text-align: center;width: 20px;">#</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let assurance of listAssurancesToSave; let indice = index">
                                    <td style="text-align: center;">{{indice + 1}}</td>
                                    <td>{{assurance?.raisonSociale || assurance?.libelle || assurance?.assuranceLibelle}} </td>
                                    <td>
                                        <input type="text" class="form-control" placeholder="Taux"
                                            [(ngModel)]="assurance.taux" mask='separator' maxlength="3" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" placeholder="N°Bon"
                                            [(ngModel)]="assurance.numeroBon" />
                                    </td>
                                    <td>
                                        <input type="text" class="form-control" placeholder="N°Carte"
                                            [(ngModel)]="assurance.numeroCarte" />
                                    </td>
                                    <!-- <td> {{ assurance?.partAssurance | moneyFormat }}</td> -->
                                    <td style="text-align: center;">
                                        <a title="Supprimer assurance" (click)="deleteItemAssurance(indice)"
                                            style="color: red;">
                                            <i class="fa fa-trash"></i>
                                        </a>
                                    </td>

                                </tr>

                                <tr *ngIf="!listAssurancesToSave || listAssurancesToSave.length == 0">
                                    <td colspan="6" class="txt-align-center"> Aucune données disponible</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>

    <div class="row">
        <ng-container>
            <div class="btn-manger-part">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding: 0">
                    <button type="button" class="btn btn-md custom_btn_primary pull-right"
                        (click)="confirmSaveItem(itemToSave)" [class.auth-spinner]="loading">
                        Enregistrer
                    </button>

                    <button type="button" class="btn btn-md btn-cancel pull-right" (click)="closeFormulaire()">
                        Fermer
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

</ng-container>