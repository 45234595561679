<form class="m-1">
    <div class="row" [ngBusy]="{
      busy: busyGet,
      message: 'Veuillez patienter ...',
      backdrop: false
    }">
        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
            <ng-container>
                <div class="details-info-part">
                    <div class="row">

                        <div class="col-md-4 col-sm-12">

                            <div class="row">
                                <div class="col-md-6 col-sm-12 mb-2">
                                    <label>N° Demande :</label>
                                    <input class="form-control custom-input" placeholder="Numero demande"
                                        autocomplete="off" [(ngModel)]="itemDemande.code" [disabled]="true"
                                        name="numerodemande" required type="text" />
                                </div>

                                <div class="col-md-6 col-sm-12 mb-2">
                                    <label>Date heure demande :</label>
                                    <input class="form-control custom-input" placeholder="Date et heure"
                                        autocomplete="off" [(ngModel)]="itemDemande.createdAt" [disabled]="true"
                                        name="numRecuTic" type="text" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-2">
                                    <label>Unité fonctionnelle :</label>
                                    <input class="form-control custom-input" placeholder="Unité fonctionnelle"
                                        autocomplete="off" [(ngModel)]="itemDemande.adminUniteFonctionnelleLibelle"
                                        [disabled]="true" name="uniteF" type="text" />
                                </div>

                                <div class="col-12 mb-2">
                                    <label for="">Magasin <span class="required_champs">*</span> : </label>
                                    <ng-select [(ngModel)]="itemToSave.magasinId" placeholder="Choisissez" name="magasinSelected"
                                        id="magasinSelected" [multiple]="false" [closeOnSelect]="true"  (change)="getQuantiteArticleByMagasin()">
                                        <ng-option *ngFor="let item of itemsPharmacieMagasin" [value]="item.id">
                                            {{item?.libelle}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                
                                <div class="col-12 mb-3">
                                    <label>Observation</label>
                                    <textarea class="form-control custom-input" placeholder="Observation"
                                        autocomplete="off" [(ngModel)]="itemToSave.observation" name="observation"
                                        required type="text"></textarea>
                                </div>

                            </div>
                        </div>


                        <div class="col-md-8">
                            <fieldset class="custom-fieldset">
                                <legend> Détails produits </legend>


                                <div class="row">
                                    <div class="col-12">
                                        <table class="table table-condensed table-striped table-hover">
                                            <thead>
                                                <tr class="custom-table-head">
                                                    <th>Médicaments</th>
                                                    <th>Quantité demandé</th>
                                                    <th  style="color: orange">Quantité Restante</th>
                                                    <th>Quantité en stock</th>
                                                    <th>Quantité livrée</th>
                                                    <th>Option</th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let item of listeMedicamentsDemandee; let indice = index">
                                                    <td>{{ item?.nomCommercialMedicament }}</td>
                                                    <td>{{ (item?.quantiteDemande | moneyFormat) || 0 }}</td>
                                                    <td style="color: orange">{{ (item?.quantiteRestante | moneyFormat) || 0 }}</td>
                                                    <td>{{ (item?.quantiteStock | moneyFormat) || 0 }}</td>
                                                    <td>
                                                        <input type="text" mask="separator" class="form-control"
                                                            name="quantiteDemande" autocomplete="off"
                                                            [ngModelOptions]="{standalone: true}"
                                                            [(ngModel)]="item.quantite" id="quantite"
                                                            style="width:140px;line-height: 12px;">
                                                    </td>
                                                    <td>
                                                        <i (click)="listeMedicamentsDemandee.splice(indice, 1)" class="fa fa-trash" style="color: red;cursor: pointer;"></i>
                                                    </td>
                                                </tr>

                                                <tr
                                                    *ngIf="!listeMedicamentsDemandee || listeMedicamentsDemandee.length == 0">
                                                    <td colspan="12" class="txt-align-center"> Aucune donnée disponible
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </fieldset>
                        </div>

                    </div>


                    <!-- <div class="row">
              
            </div> -->
                </div>
            </ng-container>
        </div>
    </div>
    <div class="row btn-manger-part">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

            <button type="button" class="btn btn-md custom_btn_primary pull-right"
                (click)="confirmSaveItem()">
               Enregistrer
            </button>

            <button type="button" class="btn btn-md btn-cancel pull-right" (click)="closeModal()">
                Fermer
            </button>

        </div>
    </div>
</form>