<div class="body">
    <section class="section-parametre" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
        <div class="">
            <div class="row clearfix">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                    <div class="card">

                        <div class="body table-responsive">
                            <!-- RETOUR VENTE DIRECT -->
                            <table class="table table-condensed" *ngIf="typeRetour == typeRetourEnumm?.VENTE_DIRECTE">
                                <thead>

                                    <tr>
                                        <th colspan="13">
                                            <div class="row mt-4">
                                                <div class="col-md-3">
                                                    <input [(ngModel)]="itemToSearch.libelle"
                                                        class="form-control custom-input pull-left"
                                                        placeholder="Rechercher ..."
                                                        (keyup)="currentPage = 1;getItems()" />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="custom-table-head">
                                        <th style="width: 35px;" class="txt-align-center">
                                            #
                                        </th>
                                        <th style="width: 40px" class="txt-align-center">
                                            <a style="cursor: pointer;" (click)="currentPage = 1;getItems()"
                                                title="Actualiser"> <i class="fa fa-redo-alt"></i></a>

                                        </th>
                                        <th>N°retour</th>
                                        <th>N°vente</th>
                                        <th>Date de vente</th>
                                        <th>Date de retour</th>
                                        <th>Nom Patient</th>
                                        <th>Motif</th>
                                        <th>Enregistré le</th>
                                        <th>Enregisté par </th>
                                        <th>Statut </th>
                                        <th class="txt-align-center" style="width: 120px;">Actions</th>
                                    </tr>
                                </thead>

                                <tbody *ngFor="let item of items; let indice = index"
                                    [class.row-paire]='indice % 2 == 0'>
                                    <tr>
                                        <td class="txt-align-center">{{indice + 1}}</td>
                                        <td class="txt-align-center">
                                            <img src="assets/images/images-app/details_open.png" class="img-details"
                                                *ngIf="!item?.isDetailsOpen" (click)="onToggle(item);" />
                                            <img src="assets/images/images-app/details_close.png" class="img-details"
                                                *ngIf="item?.isDetailsOpen" (click)="onToggle(item);" />
                                        </td>
                                        <td>{{item?.numeroBonRetour}}</td>
                                        <td>{{item?.dataVenteDirecte?.numeroVente}}</td>
                                        <td>{{item?.dataVenteDirecte?.dateVente}}</td>
                                        <td>{{item?.dateRetour}}</td>
                                        <td>{{item?.dataVenteDirecte?.nomClient}}</td>
                                        <td>{{item?.motifRetour}}</td>
                                        <td>{{item?.createdAt}}</td>
                                        <td>{{ (item?.enregistrePar || item?.enregistrerPar) | uppercase}}</td>
                                        <td> <span [class.annule]="item?.statut == 'ANNULE'" [class.termine]="item?.statut == 'TERMINE'" [class.isValide]="item?.statut == 'VALIDE'" class="badge badge-success" >{{item?.statut}}</span> </td>

                                        <td class="txt-align-center">
                                            <div ngbDropdown class="d-inline-block"  *ngIf="item?.statut != statutEnum?.ANNULE && item?.statut != statutEnum?.TERMINE">
                                                <button type="button" class="btn btn-custom-action-dropdown" id="action"
                                                    ngbDropdownToggle>Actions</button>
                                                <div ngbDropdownMenu aria-labelledby="action"
                                                    class="dropdown-validation-plainte">

                                                    <!-- <button ngbDropdownItem  *ngIf="!isValidation && item?.statut == statutEnum?.BROUILLON " (click)="confirmEnvoyerEnValidation(item)">
                                                        <i class="fa fa-check"></i>&nbsp;
                                                        Envoyer en validation
                                    
                                                    </button> -->

                                                    <button ngbDropdownItem
                                                        *ngIf="item?.statut == statutEnum?.BROUILLON"
                                                        (click)="confirmCancelRetour(item)">
                                                        <i class="fa fa-times"></i>&nbsp;
                                                        Annuler
                                                    </button>

                                                    <!-- <button ngbDropdownItem *ngIf="item?.statut == statutEnum?.VALIDE"
                                                        (click)="confirmMiseAJourRetour(item)">
                                                        <i class="fa fa-list"></i>&nbsp;
                                                        Mise à jour stock
                                                    </button> -->

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="item?.isDetailsOpen">
                                        <td colspan="13" style="background-color: #ffffff;">
                                            <div class="row" *ngIf="!isHistoriqueVente"
                                                style="background-color: #ffffff;">
                                                <div class="col-md-12 col-xs-12 col-sm-12">
                                                    <table class="table table-bordered table-condensed">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="5" class="txt-align-center">
                                                                    Produits retournés
                                                                </th>
                                                            </tr>
                                                            <tr class="custom-table-head">
                                                                <th style="width: 30px;" class="txt-align-center"> #
                                                                </th>
                                                                <th>Produit</th>
                                                                <th>Quantité</th>
                                                                <th>Prix unitaire vente</th>
                                                                <th>Montant</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody
                                                            *ngIf="item.retourVentesDirectes && item.retourVentesDirectes.length">
                                                            <tr
                                                                *ngFor="let item of item.retourVentesDirectes; let indice2 = index">
                                                                <td class="txt-align-center"> {{indice2 + 1}}</td>
                                                                <td>{{item?.nomMedivament }} </td>
                                                                <td>{{item?.quantite |moneyFormat }}
                                                                </td>
                                                                <td>{{item?.prixUnitaireVente |moneyFormat}}</td>
                                                                <td>{{ ( item?.prixUnitaireVente * item?.quantite )
                                                                    |moneyFormat}}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </td>

                                    </tr>
                                </tbody>

                                <tfoot>
                                    <tr *ngIf="items && items.length > 0">
                                        <td colspan="13">
                                            <div class="row pagination-part">
                                                <div class="col-md-9" style="text-align: center">
                                                    <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage"
                                                        [totalItems]="totalItems" [(ngModel)]="currentPage"
                                                        class="pagination-sm" previousText="&lsaquo;"
                                                        nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                                        (pageChanged)="pageChanged($event)">
                                                    </pagination>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="paginator-per-page">
                                      
                                                      <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                                                        [defaultItemPerPage]="itemsPerPage">
                                                      </app-element-by-page-paginator>
                                      
                                                      <span class="span_total_entree pull-right">Total : {{
                                                        totalItems
                                                        }}</span>
                                      
                                                    </div>
                                                  </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="!items || items.length == 0">
                                        <td colspan="13">
                                            <div class="col-md-12 txt-align-center">
                                                Aucune données disponible
                                            </div>
                                        </td>
                                    </tr>

                                </tfoot>
                            </table>
                            <!-- FIN RETOUR VENTE DIRECT -->

                              <!-- RETOUR VENTE DIRECT -->
                              <table class="table table-condensed" *ngIf="typeRetour == typeRetourEnumm?.VENTE_BON">
                                <thead>

                                    <tr>
                                        <th colspan="13">
                                            <div class="row mt-4">
                                                <div class="col-md-3">
                                                    <input [(ngModel)]="itemToSearch.libelle"
                                                        class="form-control custom-input pull-left"
                                                        placeholder="Rechercher ..."
                                                        (keyup)="currentPage = 1;getItems()" />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="custom-table-head">
                                        <th style="width: 35px;" class="txt-align-center">
                                            #
                                        </th>
                                        <th style="width: 40px" class="txt-align-center">
                                            <a style="cursor: pointer;" (click)="currentPage = 1;getItems()"
                                                title="Actualiser"> <i class="fa fa-redo-alt"></i></a>

                                        </th>
                                        <th>N°retour</th>
                                        <th>N°vente</th>
                                        <th>Date de vente</th>
                                        <th>Date de retour</th>
                                        <th>Nom Patient</th>
                                        <th>Motif</th>
                                        <th>Enregistré le</th>
                                        <th>Enregisté par </th>
                                        <th>Statut </th>
                                        <th class="txt-align-center" style="width: 120px;">Actions</th>
                                    </tr>
                                </thead>

                                <tbody *ngFor="let item of items; let indice = index"
                                    [class.row-paire]='indice % 2 == 0'>
                                    <tr>
                                        <td class="txt-align-center">{{indice + 1}}</td>
                                        <td class="txt-align-center">
                                            <img src="assets/images/images-app/details_open.png" class="img-details"
                                                *ngIf="!item?.isDetailsOpen" (click)="onToggle(item);" />
                                            <img src="assets/images/images-app/details_close.png" class="img-details"
                                                *ngIf="item?.isDetailsOpen" (click)="onToggle(item);" />
                                        </td>
                                        <td>{{item?.numeroBonRetour}}</td>
                                        <td>{{item?.datasVenteBon[0]?.numeroVente}}</td>
                                        <td>{{item?.datasVenteBon[0]?.dateVente}}</td>
                                        <td>{{item?.dateRetour}}</td>
                                        <td>{{item?.datasVenteBon[0]?.beneficiaireNom+' '+item?.datasVenteBon[0]?.beneficiairePrenom}}</td>
                                        <td>{{item?.motifRetour}}</td>
                                        <td>{{item?.createdAt}}</td>
                                        <td>{{ (item?.enregistrePar || item?.enregistrerPar) | uppercase}}</td>
                                        <td> <span [class.annule]="item?.statut == 'ANNULE'" [class.termine]="item?.statut == 'TERMINE'" [class.isValide]="item?.statut == 'VALIDE'" class="badge badge-success" >{{item?.statut}}</span> </td>

                                        <td class="txt-align-center">
                                            <div ngbDropdown class="d-inline-block"  *ngIf="item?.statut != statutEnum?.ANNULE && item?.statut != statutEnum?.TERMINE">
                                                <button type="button" class="btn btn-custom-action-dropdown" id="action"
                                                    ngbDropdownToggle>Actions</button>
                                                <div ngbDropdownMenu aria-labelledby="action"
                                                    class="dropdown-validation-plainte">

                                                    <!-- <button ngbDropdownItem  *ngIf="!isValidation && item?.statut == statutEnum?.BROUILLON " (click)="confirmEnvoyerEnValidation(item)">
                                                        <i class="fa fa-check"></i>&nbsp;
                                                        Envoyer en validation
                                    
                                                    </button> -->

                                                    <button ngbDropdownItem
                                                        *ngIf="item?.statut == statutEnum?.BROUILLON"
                                                        (click)="confirmCancelRetour(item)">
                                                        <i class="fa fa-times"></i>&nbsp;
                                                        Annuler
                                                    </button>

                                                    <!-- <button ngbDropdownItem *ngIf="item?.statut == statutEnum?.VALIDE"
                                                        (click)="confirmMiseAJourRetour(item)">
                                                        <i class="fa fa-list"></i>&nbsp;
                                                        Mise à jour stock
                                                    </button> -->

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="item?.isDetailsOpen">
                                        <td colspan="13" style="background-color: #ffffff;">
                                            <div class="row" *ngIf="!isHistoriqueVente"
                                                style="background-color: #ffffff;">
                                                <div class="col-md-12 col-xs-12 col-sm-12">
                                                    <table class="table table-bordered table-condensed">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="5" class="txt-align-center">
                                                                    Produits retournés
                                                                </th>
                                                            </tr>
                                                            <tr class="custom-table-head">
                                                                <th style="width: 30px;" class="txt-align-center"> #
                                                                </th>
                                                                <th>Produit</th>
                                                                <th>Quantité</th>
                                                                <th>Prix unitaire vente</th>
                                                                <th>Montant</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody
                                                            *ngIf="item.retourVentesDirectes && item.retourVentesDirectes.length">
                                                            <tr
                                                                *ngFor="let item of item.retourVentesDirectes; let indice2 = index">
                                                                <td class="txt-align-center"> {{indice2 + 1}}</td>
                                                                <td>{{item?.nomMedivament }} </td>
                                                                <td>{{item?.quantite |moneyFormat }}
                                                                </td>
                                                                <td>{{item?.prixUnitaireVente |moneyFormat}}</td>
                                                                <td>{{ ( item?.prixUnitaireVente * item?.quantite )
                                                                    |moneyFormat}}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </td>

                                    </tr>
                                </tbody>

                                <tfoot>
                                    <tr *ngIf="items && items.length > 0">
                                        <td colspan="13">
                                            <div class="row pagination-part">
                                                <div class="col-md-9" style="text-align: center">
                                                    <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage"
                                                        [totalItems]="totalItems" [(ngModel)]="currentPage"
                                                        class="pagination-sm" previousText="&lsaquo;"
                                                        nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                                        (pageChanged)="pageChanged($event)">
                                                    </pagination>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="paginator-per-page">
                                      
                                                      <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                                                        [defaultItemPerPage]="itemsPerPage">
                                                      </app-element-by-page-paginator>
                                      
                                                      <span class="span_total_entree pull-right">Total : {{
                                                        totalItems
                                                        }}</span>
                                      
                                                    </div>
                                                  </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="!items || items.length == 0">
                                        <td colspan="13">
                                            <div class="col-md-12 txt-align-center">
                                                Aucune données disponible
                                            </div>
                                        </td>
                                    </tr>

                                </tfoot>
                            </table>
                            <!-- FIN RETOUR VENTE DIRECT -->

                            <!-- RETOUR VENTE ACTES EXTERNES -->
                            <table class="table table-condensed"
                                *ngIf="typeRetour == typeRetourEnumm?.VENTE_ACTE_EXTERNES">
                                <thead>

                                    <tr>
                                        <th colspan="12">
                                            <div class="row mt-4">
                                                <div class="col-md-3">
                                                    <input [(ngModel)]="itemToSearch.libelle"
                                                        class="form-control custom-input pull-left"
                                                        placeholder="Rechercher ..."
                                                        (keyup)="currentPage = 1;getItems()" />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="custom-table-head">
                                        <th style="width: 35px;" class="txt-align-center">
                                            #
                                        </th>
                                        <th style="width: 40px" class="txt-align-center">
                                            <a style="cursor: pointer;" (click)="currentPage = 1;getItems()"
                                                title="Actualiser"> <i class="fa fa-redo-alt"></i></a>

                                        </th>
                                        <th>N°retour</th>
                                        <th>N°vente</th>
                                        <th>Date de retour</th>
                                        <!-- <th>Nom Patient</th> -->
                                        <th>Montant total</th>
                                        <th>Part patient</th>
                                        <th>Part assurance</th>
                                        <!-- <th>Enregistré le</th> -->
                                        <th>Enregisté par </th>
                                        <th>Statut </th>
                                        <th class="txt-align-center" style="width: 120px;">
                                            Actions</th>
                                    </tr>
                                </thead>

                                <tbody *ngFor="let item of items; let indice = index"
                                    [class.row-paire]='indice % 2 == 0'>
                                    <tr>
                                        <td class="txt-align-center">{{indice + 1}}</td>
                                        <td class="txt-align-center">
                                            <img src="assets/images/images-app/details_open.png" class="img-details"
                                                *ngIf="!item?.isDetailsOpen" (click)="onToggle(item);" />
                                            <img src="assets/images/images-app/details_close.png" class="img-details"
                                                *ngIf="item?.isDetailsOpen" (click)="onToggle(item);" />
                                        </td>
                                        <td>{{item?.numeroBonRetour}}</td>
                                        <td>{{item?.dataVenteActeExterne?.numeroVente}}</td>
                                        <td>{{item?.dateRetour}}</td>
                                        <!-- <td>{{item?.nomClient}}</td> -->

                                        <td>{{ (item?.montantTotal | moneyFormat) || 0}}</td>
                                        <td>{{ (item?.partClient | moneyFormat) || 0}}</td>
                                        <td>{{ (item?.partAssurance | moneyFormat) || 0}}</td>

                                        <!-- <td>{{item?.createdAt}}</td> -->
                                        <td>{{ (item?.enregistrePar || item?.enregistrerPar) | uppercase}}</td>
                                        <td>
                                            <span [class.annule]="item?.statut == 'ANNULE'" [class.termine]="item?.statut == 'TERMINE'" [class.isValide]="item?.statut == 'VALIDE'" class="badge badge-success" >{{item?.statut}}</span> 
                                    
                                        </td>

                                        
                                        <td class="txt-align-center">
                                        
                                            <div ngbDropdown class="d-inline-block"  *ngIf="item?.statut != statutEnum?.ANNULE && item?.statut != statutEnum?.TERMINE">
                                                <button type="button" class="btn btn-custom-action-dropdown" id="action"
                                                    ngbDropdownToggle>Actions</button>
                                                <div ngbDropdownMenu aria-labelledby="action"
                                                    class="dropdown-validation-plainte">

                                                    <!-- <button ngbDropdownItem  *ngIf="!isValidation && item?.statut == statutEnum?.BROUILLON " (click)="confirmEnvoyerEnValidation(item)">
                                                        <i class="fa fa-check"></i>&nbsp;
                                                        Envoyer en validation
                                    
                                                    </button> -->

                                                    <button ngbDropdownItem
                                                        *ngIf="item?.statut == statutEnum?.BROUILLON"
                                                        (click)="confirmCancelRetour(item)">
                                                        <i class="fa fa-times"></i>&nbsp;
                                                        Annuler
                                                    </button>

                                                    <!-- <button ngbDropdownItem *ngIf="item?.statut == statutEnum?.VALIDE"
                                                        (click)="confirmMiseAJourRetour(item)">
                                                        <i class="fa fa-list"></i>&nbsp;
                                                        Mise à jour stock
                                                    </button> -->

                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                    <tr *ngIf="item?.isDetailsOpen">
                                        <td colspan="12" style="background-color: #ffffff;">
                                            <div class="row" *ngIf="!isHistoriqueVente"
                                                style="background-color: #ffffff;">
                                                <div class="col-md-12 col-xs-12 col-sm-12">
                                                    <table class="table table-bordered table-condensed">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="5" class="txt-align-center">
                                                                    Produits retournés
                                                                </th>
                                                            </tr>
                                                            <tr class="custom-table-head">
                                                                <th style="width: 30px;" class="txt-align-center"> #
                                                                </th>
                                                                <th>Produit</th>
                                                                <th>Quantité</th>
                                                                <th>Prix unitaire vente</th>
                                                                <th>Montant</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr
                                                                *ngFor="let item of item.retourActesExternes; let indice2 = index">
                                                                <td class="txt-align-center"> {{indice2 + 1}}</td>
                                                                <td>{{item?.nomMedivament }} </td>
                                                                <td>{{item?.quantite |moneyFormat }}
                                                                </td>
                                                                <td>{{item?.prixUnitaireVente |moneyFormat}}</td>
                                                                <td>{{ ( item?.prixUnitaireVente * item?.quantite )
                                                                    |moneyFormat}}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </td>

                                    </tr>
                                </tbody>

                                <tfoot>
                                    <tr *ngIf="items && items.length > 0">
                                        <td colspan="12">
                                            <div class="row pagination-part">
                                                <div class="col-md-9" style="text-align: center">
                                                    <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage"
                                                        [totalItems]="totalItems" [(ngModel)]="currentPage"
                                                        class="pagination-sm" previousText="&lsaquo;"
                                                        nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                                        (pageChanged)="pageChanged($event)">
                                                    </pagination>
                                                </div>

                                                <div class="col-md-3">
                                                    <span class="span_total_entree pull-right">Total
                                                        : {{totalItems}}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="!items || items.length == 0">
                                        <td colspan="10">
                                            <div class="col-md-12 txt-align-center">
                                                Aucune données disponible
                                            </div>
                                        </td>
                                    </tr>

                                </tfoot>
                            </table>
                            <!-- FIN RETOUR VENTE ACTES EXTERNES -->

                            <!-- RETOUR VENTE HOSPI -->
                            <table class="table table-condensed"
                                *ngIf="typeRetour == typeRetourEnumm?.VENTE_HOSPI || typeRetour == typeRetourEnumm?.VENTE_HOSPI_BANQUE_SANG">
                                <thead>

                                    <tr>
                                        <th colspan="12">
                                            <div class="row mt-4">
                                                <div class="col-md-3">
                                                    <input [(ngModel)]="itemToSearch.libelle"
                                                        class="form-control custom-input pull-left"
                                                        placeholder="Rechercher ..."
                                                        (keyup)="currentPage = 1;getItems()" />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr class="custom-table-head">
                                        <th style="width: 35px;" class="txt-align-center">
                                            #
                                        </th>
                                        <th style="width: 40px" class="txt-align-center">
                                            <a style="cursor: pointer;" (click)="currentPage = 1;getItems()"
                                                title="Actualiser"> <i class="fa fa-redo-alt"></i></a>

                                        </th>
                                        <th>N°retour</th>
                                        <th>N°vente</th>
                                        <th>Date de retour</th>
                                        <th>Nom Patient</th>
                                        <!-- <th>Montant total</th>
                                       <th>Part patient</th>
                                       <th>Part assurance</th> -->
                                        <th>Enregisté par </th>
                                        <th>Statut </th>
                                        <th class="txt-align-center" style="width: 120px;">
                                            Actions</th>
                                    </tr>
                                </thead>

                                <tbody *ngFor="let item of items; let indice = index"
                                    [class.row-paire]='indice % 2 == 0'>
                                    <tr>
                                        <td class="txt-align-center"> {{getExactlyNumberRow(this.currentPage,indice)}}
                                        </td>

                                        <td class="txt-align-center">
                                            <img src="assets/images/images-app/details_open.png" class="img-details"
                                                *ngIf="!item?.isDetailsOpen" (click)="onToggle(item);" />
                                            <img src="assets/images/images-app/details_close.png" class="img-details"
                                                *ngIf="item?.isDetailsOpen" (click)="onToggle(item);" />
                                        </td>
                                        <td>{{item?.numeroBonRetour}}</td>
                                        <td>{{item?.dataVenteHospi?.numeroVente}}</td>
                                        <td>{{item?.dateRetour}}</td>
                                        <td>{{item?.nomClient}}</td>
                                        <td>{{ (item?.enregistrePar || item?.enregistrerPar) | uppercase}}</td>
                                        <td>
                                            <span [class.annule]="item?.statut == 'ANNULE'" [class.termine]="item?.statut == 'TERMINE'" [class.isValide]="item?.statut == 'VALIDE'" class="badge badge-success" >{{item?.statut}}</span> 
                                        </td>

                                        <td class="txt-align-center">
                                            <div ngbDropdown class="d-inline-block"  *ngIf="item?.statut != statutEnum?.ANNULE && item?.statut != statutEnum?.TERMINE">
                                                <button type="button" class="btn btn-custom-action-dropdown" id="action"
                                                    ngbDropdownToggle>Actions</button>
                                                <div ngbDropdownMenu aria-labelledby="action"
                                                    class="dropdown-validation-plainte">
                                                    <button ngbDropdownItem
                                                        *ngIf="item?.statut == statutEnum?.BROUILLON"
                                                        (click)="confirmCancelRetour(item)">
                                                        <i class="fa fa-times"></i>&nbsp;
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="item?.isDetailsOpen">
                                        <td colspan="12" style="background-color: #ffffff;border: 8px solid #eceeee;">
                                            <div class="row" *ngIf="!isHistoriqueVente"
                                                style="background-color: #ffffff;">
                                                <div class="col-md-12 col-xs-12 col-sm-12">
                                                    <table class="table table-bordered table-condensed">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="5" class="txt-align-center">
                                                                    Produits retournés
                                                                </th>
                                                            </tr>
                                                            <tr class="custom-table-head">
                                                                <th style="width: 30px;" class="txt-align-center"> #
                                                                </th>
                                                                <th>Produit</th>
                                                                <th>Quantité</th>
                                                                <th>Prix unitaire vente</th>
                                                                <th>Montant</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr
                                                                *ngFor="let item of item.retourVenteHospi; let indice2 = index">
                                                                <td class="txt-align-center"> {{indice2 + 1}}</td>
                                                                <td>{{item?.nomMedivament }} </td>
                                                                <td>{{item?.quantite |moneyFormat }}
                                                                </td>
                                                                <td>{{item?.prixUnitaireVente |moneyFormat}}</td>
                                                                <td>{{ ( item?.prixUnitaireVente * item?.quantite )
                                                                    |moneyFormat}}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </td>

                                    </tr>
                                </tbody>

                                <tfoot>
                                    <tr *ngIf="items && items.length > 0">
                                        <td colspan="12">
                                            <div class="row pagination-part">
                                                <div class="col-md-9" style="text-align: center">
                                                    <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage"
                                                        [totalItems]="totalItems" [(ngModel)]="currentPage"
                                                        class="pagination-sm" previousText="&lsaquo;"
                                                        nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                                        (pageChanged)="pageChanged($event)">
                                                    </pagination>
                                                </div>

                                                <div class="col-md-3">
                                                    <span class="span_total_entree pull-right">Total
                                                        : {{totalItems}}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="!items || items.length == 0">
                                        <td colspan="10">
                                            <div class="col-md-12 txt-align-center">
                                                Aucune données disponible
                                            </div>
                                        </td>
                                    </tr>

                                </tfoot>
                            </table>
                            <!-- FIN RETOUR VENTE HOSPI -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</div>