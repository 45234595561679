import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-main-details-plainte',
  templateUrl: './main-details-plainte.component.html',
  styleUrls: ['./main-details-plainte.component.scss']
})
export class MainDetailsPlainteComponent implements OnInit {

  @Input() plainte;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    console.log(" changes['plainte'] ",changes['plainte']);
    
    if(changes['plainte'] && changes['plainte'].currentValue)
    {

    }

  }


}
