import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import { enumStatut } from 'src/app/core/enumerator/enumerator';

@Component({
  selector: 'app-liste-requetes-dialyse',
  templateUrl: './liste-requetes-dialyse.component.html',
  styleUrls: ['./liste-requetes-dialyse.component.scss']
})
export class ListeRequetesDialyseComponent implements OnInit {

  listItems: Array<any> = [];
  items: Array<any> = [];
  itemToSave: any = {};
  modalRef: BsModalRef;
  user: any = {};
  itemToSearch: any = {};
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  busyGet: Subscription;
  busySave: Subscription;
  statutEnum : any;
  loading: boolean = false;
  endPoint : string = 'dialyseAccueil/';
  @Output() requeteSelected: EventEmitter<any> = new EventEmitter();
  @Output() traiterRequeteSelected: EventEmitter<any> = new EventEmitter();
  @Output() requeteForProgramSelected: EventEmitter<any> = new EventEmitter();

  @Input() hasNeedTitleTable : any;
  @Input() libelleBouton : any;
  @Input() refreshItem : any;
  @Input() hasNeedBtnTraiter : any;
  @Input() statutDialyse : any;
  @Input() isHistoFromReception : boolean;
  @Input() isProgram : boolean;
  @Input() hasBtnProgramme : boolean;

  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
    this.statutEnum = enumStatut;
  }

  seletedRequeteForProgram(requete) {
    this.requeteForProgramSelected.emit(requete);
  }

  selectedRequete(item,isTraiter) {
    if(item) {
      if(!isTraiter) this.requeteSelected.emit(item);
      if(isTraiter) this.traiterRequeteSelected.emit(item);
    }
  }

  changePaginationSize($event) {
    if($event) {
      this.currentPage = 1;
      this.itemsPerPage = parseInt($event);
    }
    this.getItems();
  }

  pageChanged(event: any): void {
    this.currentPage = event.page ;
    this.getItems();
  }

  openModal(data: any, template: TemplateRef<any>) {

    let config = {backdrop: true, ignoreBackdropClick: true};
    this.itemToSave = {};
    if (data) {
      // Lorsque nous sommes en modification
      this.itemToSave = Object.assign({}, data);
    }
    this.modalRef = this.modalService.show(template,Object.assign({},config, { class: 'modal-lg modal-width-75' }));

  }

  fermertureFormulaire(){
    this.modalRef.hide();
  }

  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer
      ]
    }

    this.busySave = this.restClient.post(this.endPoint+''+ (itemAEnregistrer.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");

              this.getItems();
              this.modalRef.hide();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  getItems() {
    let request = {
      user: this.user.id, 
      data: {
        numeroRequete: this.itemToSearch.libelle ? this.itemToSearch.libelle : null,
        statut : this.statutDialyse || null,
        isProgram : this.isProgram || null
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage
    }

    this.busyGet = this.restClient.post('dialyseRequete/getByCriteria', request)
      .subscribe(
        res => {

          console.log(" resultat dialyseRequete ",res);
          
          if (res && res['items']) {
            this.items = res['items'];
            this.totalItems = res['count'];
          }
          else {
            this.items = [];
            this.totalItems = 0;
          }
        },
        err => {
        }
      );
  }


  getMontantTotalAssurance(items){
    let montantTotal = 0;
    if(items && items.length > 0){
       montantTotal = _.sumBy(items, (o)=> { return parseFloat(o.partAssurance); });
    }

    return montantTotal;  
  }

  getExactlyNumberRow(page,index)
  {
      let num = index +1;
      if(page>1)
      {
          num = ((page - 1) * 10) + (index+1);
      }
      return num;
  }
  
  ngOnInit() {
    this.getItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['refreshItem'] && changes['refreshItem'].currentValue)
    {
      this.getItems();
    }
  }

}
