  <div class="modal-body" [ngBusy]="{
    busy: busySave,
    message: 'Veuillez patienter ...',
    backdrop: false
  }">
  <div class="lock-screen">
    <div class="lock-screen-modal">
        <form (ngSubmit)="loginUser()">
            <h2>Verrouillé</h2>
      <p>Veuillez rentrer votre mot de passe pour devérouiller</p>
      <input type="password" name="motDepass" [(ngModel)]="motDepass" autocomplete="new-password"  placeholder="Mot de passe" (focus)="clearField($event)">
      <!-- <button *ngIf="!isLoading" (click)="loginUser()">Devérouiller</button> -->
      <button *ngIf="!isLoading"  type="submit">Devérouiller</button>
      <button *ngIf="isLoading" class="btn btn-primary" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        En cours...
      </button>
        </form>
      
    </div>
  </div>
</div>
