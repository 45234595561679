import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {

    if(localStorage.getItem('currentUser')) {
      try {
         this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')));
       this.currentUser = this.currentUserSubject.asObservable();
      } catch (error) {
        console.log(" error ",error);
      } 
    }
   
  }

  public get currentUserValue(): User {
    if(this.currentUserSubject) {
      return this.currentUserSubject.value;
    }else{
      return null;
    }
   
  }

  public isUserAuthed(){
    // return this.currentUserValue &&  !_.isNull(this.currentUserValue.id);
    return localStorage.getItem('currentUser') !== null;
  }

  public  setCurrentUser(item?:any) {
    // Le currentUserSubject est souvent null
    if(!this.currentUserSubject) {
      this.currentUserSubject = new BehaviorSubject<User>(new User);
      this.currentUserSubject.next(item || JSON.parse(localStorage.getItem('currentUser')));
    }else{
      this.currentUserSubject.next(item || JSON.parse(localStorage.getItem('currentUser')));
    }
  }

  getActiveUniteFonctionnelle(){
    let user = this.currentUserValue
    let cuurentId = user.uniteFoncActiveId
    if(user.uniteFonctionnelles && user.uniteFonctionnelles.length){
      let uf = user.uniteFonctionnelles.filter((uf:any)=>uf.idUniteFonctionnelle == cuurentId)[0]
      uf.id = uf.idUniteFonctionnelle
      uf.libelle = uf.libelleUniteFonctionnelle
      return uf
    }
    return {}
  }

  login(username: string, password: string,isFirstAttempt,activeUniteFonctionnelleId?) {
    let request={
      data: {
        login: username,
        password: password,
        isFirstAttempt:isFirstAttempt,
        uniteFoncActiveId:activeUniteFonctionnelleId,
      }
  }
    return this.http
      .post<any>(`${environment.apiUrl}/user/login`, request)
      .pipe(
        map((user:any) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if(user && user.items && user.items.length){

            console.log(" user.items ",user.items);
            
            // Faire remonter serviceId
            if(user?.items && user?.items?.length > 0) {
              let currentUniteFonctionnelle = _.find(user.items[0]?.uniteFonctionnelles , (o) => { return o.idUniteFonctionnelle == user.items[0]?.uniteFoncActiveId });
              user.items[0].currentFonctionnaliteSelected = currentUniteFonctionnelle;
            }

            localStorage.setItem('currentUser', JSON.stringify(user.items[0]));
            this.currentUserSubject.next(user.items[0]);
          }
          return user;
        })
      );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // this.currentUserSubject.next(new User);
    return of({ success: false });
  }
}
