<div class="row">
    <div class="col-md-7">
        <table class="table table-bordered table-striped">
            <tbody>
        
                <tr>
                    <td>Date et Heure</td>
                    <td> <b class="b-value"> {{plainte?.createdAt}}</b></td>
                </tr>
        
                <tr>
                    <td> Nom & Prénoms plaingnant</td>
                    <td> <b class="b-value"> {{ (plainte?.beneficiaireNom+' '+plainte?.beneficiairePrenom) || "-"}}</b></td>
                </tr>
                <tr>
                    <td> Télephone plaingnant</td>
                    <td> <b class="b-value"> {{ (plainte?.telephonePlaignant) || "-"}}</b></td>
                </tr>
                <tr>
                    <td> Unité fonctionnelle</td>
                    <td> <b class="b-value"> {{ (plainte?.adminUniteFonctionnelleLibelle) || "-"}}</b></td>
                </tr>
        
                <tr>
                    <td> Médecin</td>
                    <td> <b class="b-value"> {{ (plainte?.nomcompletPlaigant) || "-"}}</b></td>
                </tr>

                <tr>
                    <td> Justification médecin</td>
                    <td> <b class="b-value"> {{ (plainte?.justification) || "-"}}</b></td>
                </tr>
        
                <tr>
                    <td> Description</td>
                    <td> <b class="b-value"> {{ (plainte?.description) || "-"}}</b></td>
                </tr>

            </tbody>
        </table>
    </div>
    
    <div class="col-md-5" *ngIf="plainte?.motifPlainte && plainte?.motifPlainte?.length > 0">
        <table class="table table-bordered table-condensed table-striped table-reception">
            <thead>
                <tr class="custom-table-head">
                    <th style="text-align: center;width: 40px;">#</th>
                    <th>Motif plainte</th>
                </tr>
            </thead>
    
            <tbody>
                <tr
                    *ngFor="let item of plainte?.motifPlainte; let indice =  index">
                    <td style="text-align: center;"> {{indice + 1}} </td>
                    <td> {{ (item?.libelle ||item?.motifPlainteLibelle) | uppercase}} </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
