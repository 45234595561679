<div class=""  [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">
    <table class="table table-condensed table-striped table-hover">
        <thead>
            <tr *ngIf="hasNeedTitleTable">
                <th colspan="12" class="txt-align-center">
                    Liste des requêtes de dialyse
                </th>
            </tr>
          
            <tr>
                <th colspan="12">
                    <div class="row mt-4 search-part">
                        <div class="col-md-3">
                            <input [(ngModel)]="itemToSearch.libelle"
                                class="form-control custom-input pull-left"
                                placeholder="Rechercher par le numéro de requete ..." (keyup)="currentPage = 1;getItems()" />
                        </div>
                       
                    </div>
                </th>
            </tr>
            <tr class="custom-table-head">
             
                <th class="txt-align-center">
                    <a (click)="currentPage = 1;getItems()" title="Actualiser"> <i class="fa fa-redo-alt"></i></a>&nbsp;&nbsp;
                    &nbsp;&nbsp;#
                </th>
                <th>N°Requête</th>
                <th>Enregistre le </th>
                <th>Matricule</th>
                <th>Patient</th>
                <th>Type de dialyse</th>
                <th>Jour</th>
                <th>Période</th>
                <th>Statut</th>
                <th>Enregistré par</th>
                <th class="txt-align-center" style="width: 200px">Actions</th>
            </tr>
        </thead>

        <tbody >
            <tr  *ngFor="let item of items; let indice = index" [class.hasCancel]="item?.statut?.toLowerCase() == statutEnum?.REJETE?.toLowerCase()">
                
                <td class="txt-align-center">  {{getExactlyNumberRow(this.currentPage,indice)}}</td>
                <td>{{item?.numeroRequete}}</td>
                <td>{{item?.createdAt}}</td>
                <td>{{item?.dataBeneficiaire?.matriculeBeneficiaire}}</td>
                <td>{{item?.dataBeneficiaire?.nom + ' '+item?.dataBeneficiaire?.prenom}}</td>
                <td>{{item?.dialyseTypeLibelle}}</td>
                <td>{{item?.dialyseJourLibelle}}</td>
                <td>{{item?.dialysePeriodeLibelle}}</td>
                <td>{{item?.statut | uppercase}}</td>
                <td>{{(item?.enregistrePar || item?.enregistrePar) | uppercase}}</td>
                <td class="txt-align-center">
                    <div class="btn-manager">
                        <button type="button" class="btn btn-md btn-details "  (click)="selectedRequete(item,true)" *ngIf="hasNeedBtnTraiter && isHistoFromReception && item?.statut?.toLowerCase() != statutEnum?.REJETE?.toLowerCase()"> &nbsp; Traiter </button>
                        <button type="button" class="btn btn-md btn-details "  (click)="seletedRequeteForProgram(item)" *ngIf="hasBtnProgramme && item?.statut?.toLowerCase() != statutEnum?.VALIDE?.toLowerCase()"> &nbsp; Programme </button>
                        <button type="button" class="btn btn-md custom_btn_primary"  (click)="selectedRequete(item,false)"> &nbsp; {{libelleBouton ? libelleBouton : 'Sélectionner'}} </button>
                      
                        
                    </div>
                  

                </td>
            </tr>

            <tr *ngIf="items && items.length > 0">
                <td colspan="12">
                    <div class="row pagination-part">
                        <div class="col-md-6" style="text-align: center">
                            <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
                                [(ngModel)]="currentPage" class="pagination-sm" previousText="&lsaquo;"
                                nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                (pageChanged)="pageChanged($event)"></pagination>
                        </div>

                        <div class="col-md-6">
                            <div class="paginator-per-page">
              
                              <app-element-by-page-paginator (paginationChange)="changePaginationSize($event)"
                                [defaultItemPerPage]="itemsPerPage">
                              </app-element-by-page-paginator>
              
                              <span class="span_total_entree pull-right">Total : {{
                                totalItems
                                }}</span>
              
                            </div>
                          </div>
                    </div>
                </td>
            </tr> 

            <tr *ngIf="!items || items.length == 0">
                <td colspan="12">
                    <div class="col-md-12 txt-align-center">
                        Aucune données disponible
                    </div>
                </td>
            </tr>

        </tbody>

      
    </table>
</div>
        