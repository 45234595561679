
    <div class="" [ngBusy]="{
      busy: busyGet,
      message: 'Veuillez patienter ...',
      backdrop: false
    }">
        <table class="table table-condensed">
            <thead>
              <tr>
                <th colspan="10">
                  <div class="row mt-4">
                    <!-- <div class="col-md-3">
                      <input [(ngModel)]="itemToSearch.libelle"
                        class="form-control custom-input pull-left" placeholder="Rechercher ..."
                        (keyup)="currentPage = 1; getItems()" />
                    </div> -->

                    <div class="col-md-4 col-sm-12">
                        <!-- <ng-select [(ngModel)]="itemToSearch.uniteFoncId" [disabled]="!isLivraisonAuService" placeholder="Choisissez une unité fonctionnelle"
                            name="uniteFc" id="uniteFc" [multiple]="false" 
                            (change)="currentPage = 1; getItems(itemToSearch.uniteFoncId)" [closeOnSelect]="true">
                            <ng-option *ngFor="let item of itemsUniteFonctionnelle" [value]="item.id">
                                {{item?.libelle}}
                            </ng-option> 
                        </ng-select> -->

                        <!-- <input [(ngModel)]="currentUniteFonct.libelle"
                        class="form-control custom-input pull-left" name="uniteFc" disabled /> -->

                    </div>
                    <div class="col-md-8" *ngIf="!isLivraisonAuService">
                      <button type="button" class="btn btn-md custom_btn_primary pull-right"
                        (click)="openModal(null, templateCrud)">
                        <i class="fa fa-plus"></i>&nbsp; Nouvelle demande
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
              <tr class="custom-table-head">
                <th  class="txt-align-center-width-40"> #</th>
                <th  class="txt-align-center-width-40">
                  <a style="cursor: pointer" (click)="currentPage = 1; getItems(itemToSearch.uniteFoncId)" title="Actualiser">
                    <i class="fa fa-redo-alt"></i></a> 
                </th>
                <th>N° Demande</th>
                <th>Date demande</th>
                <th>Unité fonctionnelle</th>
                <th>Observ. demande</th>
                <th class="txt-align-center">Statut livraison</th>
                <th>Enregistré par</th>
                <th class="txt-align-center" style="width:170px">
                  Actions
                </th>
              </tr>
            </thead>
        
            <tbody *ngFor="let item of items; let indice = index" [class.row-paire]="indice % 2 == 0">
              <tr>
                <td class="txt-align-center">{{ indice + 1 }}</td>
                <td>
                  <img src="assets/images/images-app/details_open.png" class="img-details"
                    *ngIf="!item?.isDetailsOpen" (click)="onToggle(item)" />
                  <img src="assets/images/images-app/details_close.png" class="img-details"
                    *ngIf="item?.isDetailsOpen" (click)="onToggle(item)" />
                </td>
                <td>{{ item?.code }}</td>
                <td>{{ item?.createdAt }}</td>
                <td>{{item?.adminUniteFonctionnelleLibelle}}</td>
                <td>{{ item?.observation }}</td>
                
    
                <td class="txt-align-center"> 
                  <span class="badge" [class.badge-success]="(item?.statut == statutEnum?.TERMINE || item?.statut == statutEnum?.VALIDE ) "  [class.badge-orange]="item?.statut == statutEnum?.EN_COURS_TRAITEMENT"  [class.badge-danger]="item?.statut == 'ANNULE'">{{item?.statut}}</span>
              </td>
        
                <td>{{ (item?.enregistrePar || item?.enregistrePar) | uppercase }}</td>

                <td *ngIf="isLivraisonAuService">
                    <div class="custom-td-action" *ngIf="!item?.isLivre">
                        <button title="Enregistrer une livraison" (click)="openModal(item,templateEnregistrerLivraison)"
                            class="btn custom_btn_primary custom-btn-update"
                           >
                            Enregister une livraison </button>

                    </div>
                </td>

                <td class="txt-align-center" *ngIf="!isLivraisonAuService">

                  <div class="custom-td-action" *ngIf="item?.statut != 'ANNULE' && item?.statut != 'TERMINE' && item?.statut != 'VALIDE' ">
                      <div ngbDropdown class="d-inline-block"  *ngIf="item?.statut != 'ANNULE' && item?.statut != 'TERMINE' && item.statut != 'EN COURS DE TRAITEMENT'">
                        <button type="button" class="btn btn-custom-action-dropdown" id="action"
                            ngbDropdownToggle>Actions</button>
                        <div ngbDropdownMenu aria-labelledby="action" class="dropdown-validation-plainte">
                            
                            <button ngbDropdownItem (click)="openModal(item, templateCrud)">
                                &nbsp;
                                Modifier 
                            </button>

                            <button ngbDropdownItem (click)="confirmSendValidation(item)" *ngIf="item.statut != 'EN COURS DE TRAITEMENT'">
                              &nbsp;
                              Envoyer en validation 
                          </button>

                          <button ngbDropdownItem  (click)="confirmDelete(item)">
                            &nbsp;
                            Supprimer 
                        </button>
                           

                        </div>
                    </div>

                  </div>
                </td>
              </tr>
              <tr *ngIf="item?.isDetailsOpen">
                <td colspan="9">
                  <div class="row" class="row" style="background-color: #ffffff">
                    <div class="col-md-12 col-xs-12 col-sm-12">
                      <table class="table table-bordered table-condensed">
                        <thead>
                          <tr class="custom-table-head">
                            <th style="width: 2%">#</th>
                            <th>Nom medicaments</th>
                            <th> Quantité actuelle stock</th>
                            <th>Quantité demandé</th>
                            <th>Quantité livré</th>
                          </tr>
                        </thead>
        
                        <tbody>
                          <tr *ngFor=" let item of itemsMedicament; let indice2 = index">
                            <td class="txt-align-center">{{ indice2 + 1 }}</td>
                            <td> {{ item?.nomCommercialMedicament }} </td>
                            <td> {{(item?.quantiteStock | moneyFormat) || 0 }} </td>
                            <td> {{(item?.quantiteDemande | moneyFormat) || 0 }} </td>
                            <td> {{( ( (item?.quantiteDemande || 0) - (item?.quantiteRestante || 0) ) | moneyFormat) || 0 }} </td>
                          </tr>
                        </tbody>
        
                      </table>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
        
            <tfoot>
              <tr *ngIf="items && items.length > 0">
                <td colspan="9">
                  <div class="row pagination-part">
                    <div class="col-md-9" style="text-align: center">
                      <pagination [maxSize]="5" [boundaryLinks]="true" [itemsPerPage]="itemsPerPage"
                        [totalItems]="totalItems" [(ngModel)]="currentPage" class="pagination-sm"
                        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                        (pageChanged)="pageChanged($event)"></pagination>
                    </div>
        
                    <div class="col-md-3">
                        <div class="paginator-per-page">

                            <app-element-by-page-paginator
                                (paginationChange)="changePaginationSize($event)"
                                [defaultItemPerPage]="itemsPerPage">
                            </app-element-by-page-paginator>

                            <span class="span_total_entree pull-right">Total : {{ totalItems
                                }}</span>

                        </div>
                    </div>
                  </div>
                </td>
              </tr>
        
              <tr *ngIf="!items || items.length == 0">
                <td colspan="8">
                  <div class="col-md-12 txt-align-center">
                    Aucune données disponible
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
    </div>

    <ng-template #templateCrud>
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            {{
            itemToSave?.id
            ? "Modification de la demande de produit"
            : "Enregistrement de la demande de produit"
            }}
          </h4>
          <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body" >
            <div class="">
       
                <app-form-produits-unit-fonct (close)="modalRef.hide(); getItems()" [itemReglement]="itemToSave">
                </app-form-produits-unit-fonct>
            </div>
        </div>
      </ng-template>
      
      <ng-template #templateEnregistrerLivraison>
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            Enregistrer une livraison
          </h4>
          <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="modal-body" >
            <div class="">
       
                <app-form-enregistrer-livraison (close)="modalRef.hide(); getItems()" [itemDemande]="itemToSave">
                </app-form-enregistrer-livraison>
            </div>
        </div>
      </ng-template>

      

