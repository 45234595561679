<ng-container>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3 col-sm-12">
                    <b>Dosage</b>
                    <ul>
                        <li *ngFor="let item of listeDosages" (click)="selectedDosage(item)" [class.activePosologie]="item?.toLowerCase() == dosageSelected?.toLowerCase()">{{item}}</li>
                    </ul>
                </div>
        
                <div class="col-md-2 col-sm-12">
                    <b>Posologie</b>
                    <ul>
                        <li *ngFor="let item of listePosologies" (click)="selectedPosologie(item)" [class.activePosologie]="item?.toLowerCase() == posologieSelected?.toLowerCase()">{{item}}</li>
                    </ul>
                </div>
        
                <div class="col-md-4 col-sm-12">
                    <b>Durée</b>
                    <ul>
                        <li *ngFor="let item of listeDurees" (click)="selectedDuree(item)" [class.activePosologie]="item?.toLowerCase() == dureeSelected?.toLowerCase()">{{item}}</li>
                    </ul>
                </div>

                <div class="col-md-3 col-sm-12">
                    <b>Détails</b>
                    <ul>
                        <li *ngFor="let item of listeDetails" (click)="selectedDetails(item)" [class.activePosologie]="item?.toLowerCase() == detaisSelected?.toLowerCase()">{{item}}</li>
                    </ul>
                </div>

            </div>
        
            <div class="row">
                <div class="btn-manger-part">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                     
                      <button type="button" class="btn btn-md custom_btn_primary pull-right" (click)="setReinitValue()">
                        Reinitialiser
                      </button>
          
                      <button type="button" class="btn btn-md btn-cancel pull-right" (click)="closePop()">
                        Fermer
                      </button>
                    </div>
                  </div>
            </div>
        </div>

    </div>
    
</ng-container>