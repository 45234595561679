import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { Role } from "./core/models/role";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { TableauDeBordComponent } from "./tableau-de-bord/tableau-de-bord.component";
import { HomePageComponent } from "./home-page/home-page.component";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      // { path: "tableau-de-bord", component:TableauDeBordComponent },
      { path: "tableau-de-bord", canActivate: [AuthGuard],
      loadChildren: () =>
        import("./tableau-de-bord/tableau-de-bord.module").then(
          (m) => m.TableauDeBordModule
        ),
       },
      {
        path: "admin",
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "accueil",
        canActivate: [AuthGuard],
        component:HomePageComponent
      },
      {
        path: "extra-pages",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./extra-pages/extra-pages.module").then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: "parametres",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./parametres/parametres.module").then(
            (m) => m.ParametresModule
          ),
      },
      {
        path: "administration",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./administration/administration.module").then(
            (m) => m.AdministrationModule
          ),
      },
      {
        path: "hospitalisation",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./hospitalisation/hospitalisation.module").then(
            (m) => m.HospitalisationModule
          ),
      },
      {
        path: "pharmacie",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./pharmacie/pharmacie.module").then((m) => m.PharmacieModule),
      },
      {
        path: "bureau-des-entrees",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./bureau-des-entrees/bureau-des-entrees.module").then(
            (m) => m.BureauDesEntreesModule
          ),
      },
      {
        path: "tresorerie",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./tresorerie/tresorerie.module").then(
            (m) => m.TresorerieModule
          ),
      },
      {
        path: "gestion-consomation",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./gestion-consomation/gestion-consomation.module").then(
            (m) => m.GestionConsomationModule
          ),
      },
      {
        path: "gestion-commerciale",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./gestion-commerciale/gestion-commerciale.module").then(
            (m) => m.GestionCommercialeModule
          ),
      },
      {
        path: "service-technique",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./service-technique/service-technique.module").then(
            (m) => m.ServiceTechniqueModule
          ),
      },
      {
        path: "etats",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./etats/etats.module").then(
            (m) => m.EtatsModule
          ),
      },

      {
        path: "laboratoire",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./laboratoire/laboratoire.module").then(
            (m) => m.LaboratoireModule
          ),
      },
      {
        path: "radiologie",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./radiologie/radiologie.module").then(
            (m) => m.RadiologieModule
          ),
      },
      {
        path: "unite-consultation",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./unite-consultation/unite-consultation.module").then(
            (m) => m.UniteConsultationModule
          ),
      },
      {
        path: "planning",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./planning/planning.module").then((m) => m.PlanningModule),
      },
      {
        path: "rdv",
        canActivate: [AuthGuard],
        loadChildren: () => import("./rdv/rdv.module").then((m) => m.RdvModule),
      },
      {
        path: "reclamation",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./reclamation/reclamation.module").then(
            (m) => m.ReclamationModule
          ),
      },
      {
        path: "honoraire",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./honoraire/honoraire.module").then(
            (m) => m.HonoraireModule
          ),
      },
      // {
      //   path: "honoraire",
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import("./honoraire/honoraire.module").then(
      //       (m) => m.HonoraireModule
      //     ),
      // },
      {
        path: "gestion-administrative",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./gestion-administrative/gestion-administrative.module").then(
            (m) => m.GestionAdministrativeModule
          ),
      },
      {
        path: "gestion-buanderie",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./buanderie/buanderie.module").then(
            (m) => m.BuanderieModule
          ),
      }
      ,
      {
        path: "prevention",
        canActivate: [AuthGuard],
        loadChildren: () => import("./prevention/prevention.module").then(
          (m) => m.PreventionModule
        ),
      },

    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
