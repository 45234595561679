import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/service/auth.service";
@Component({
  selector: "app-page404",
  templateUrl: "./page404.component.html",
  styleUrls: ["./page404.component.scss"],
})
export class Page404Component implements OnInit {
  constructor(private authService:AuthService,private router:Router) {}
  goBack(){
    window.history.back()
  }
  logOut(){
    this.authService.logout()
    this.router.navigate(["/authentication/signin"]);
  }
  ngOnInit() {}
}
