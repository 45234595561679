import { Component, OnInit } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { PlatformLocation } from "@angular/common";
import { AutoLogoutService } from "./core/service/auto-logout.service";
import { AutoLockScreenService } from "./core/service/auto-lock-screen.service";
import { LockScreenModalComponent } from "./core/service/lock-screen-modal/lock-screen-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { AuthService } from "./core/service/auth.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  currentUrl: string;
  modalRef: any;
  constructor(private authService:AuthService,public _router: Router, location: PlatformLocation,private autotLogout: AutoLogoutService, private autoLockService:AutoLockScreenService,private modalService: BsModalService) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        // location.onPopState(() => {
        //   window.location.reload();
        // });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
      }
      window.scrollTo(0, 0);
    });
  }

  showLockScreenModal(){
    let isLocked = localStorage.getItem('isLocked')
    // if(isLocked == 'true' && this.isModalOpen()){
    //   return
    // }

    // debugger

    if(isLocked == 'false'){
      // this.modalRef.hide()
      return
    }
   

    let modal:any
    modal = LockScreenModalComponent
    this.modalRef = this.modalService.show(modal, {
      id: 1,
      class: 'modal-lock-screen',
    });

    // if(!this.authService.isUserAuthed() && this.isModalOpen()){
    //   this.modalRef.hide()
    //   return
    // }


    localStorage.setItem('isLocked',this.isModalOpen()?'true':'false')
    console.log('is open: ', this.isModalOpen())
    this.modalRef.onHide.subscribe((res) => {
      console.log('closed')
    localStorage.setItem('isLocked',this.isModalOpen()?'true':'false')
    });
  }

  isModalOpen(): boolean {
    return this.modalService.getModalsCount() > 0;
  }
  ngOnInit() {
    this.showLockScreenModal()
    // localStorage.setItem('isLocked','false')
    this.autoLockService.startTimer();
  }
  
}
