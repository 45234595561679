import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/service/auth.service';
import { RestClientService } from 'src/app/core/service/rest-client.service';
import { UtilitiesService } from 'src/app/core/service/utilities.service';
import Swal from "sweetalert2";
import * as _ from "lodash";
import * as moment from 'moment';
import { enumStatut } from 'src/app/core/enumerator/enumerator';

@Component({
  selector: 'app-form-certificat-medical',
  templateUrl: './form-certificat-medical.component.html',
  styleUrls: ['./form-certificat-medical.component.scss']
})
export class FormCertificatMedicalComponent implements OnInit {

  @Input() currentPatient: any;
  @Input() consultation: any;
  @Output() closeForm = new EventEmitter<any>();
  @Input() isHistoConsultation: any;
  isUpdate : boolean;

  listeTypeDocuments : any = [];
  listeChamps : any = [];
  itemDocselected : any = {};
  itemOldOrdonance : any = {};
  refreshItem: any;
  itemToSaveMedicament: any = {};
  currentMedicament: any = {}
  listMedicaments: Array<any> = [];
  listLieuEvacuation : Array<any> = [];
  listNiveauUrgence : Array<any> = [];
  itemToSave: any = {};
  busyGet: Subscription;
  listTypeAntecedent: Array<any> = [];
  itemDescription : any;
  listPathologies: Array<any> = [];
  listPathologiesToSave: Array<any> = [];
  user: any = {};
  loading: boolean;
  dateActuelle = new Date();

  constructor(private authService: AuthService, private restClient: RestClientService, private modalService: BsModalService, private utilities: UtilitiesService) {
    this.user = this.authService.currentUserValue;
  }

  closeFormulaire() {
    this.closeForm.emit(true);
  }

  getOldData() {
    let request = {
      user: this.user.id,
      data: {
        consultationId: this.consultation?.id
      }
    }

    this.busyGet = this.restClient.post('adminCertificat/getByCriteria', request)
      .subscribe(
        res => {

          console.log(" res cert ",res);
          
          if (res && res['items'] && res['items'].length > 0) {
            this.itemToSave = (res['items']?.length > 0) ? res['items'][0] : {};

            if(this.itemToSave.statut != enumStatut.ANNULE) {
              this.itemDescription = this.itemToSave.modele;
                // Preselectionner le type
              this.isUpdate = true;
            }else {
              this.itemToSave = {};
              this.isUpdate = false;
            }
            this.getData();
          }
          else {
            this.itemDescription = "";
            this.itemToSave = {}
            this.getData();
          }
        },
        err => {
        }
      );
  }

  getDescription() {
    this.itemDocselected  = {...this.itemToSave.typeDocumentsSelected};
    this.itemDescription = this.itemDocselected?.modele;

    this.formatCertificat();

    this.getChampsTypeCertificat();
  }

  formatCertificat() {

    /** On remplace le nom du docteutr */
    this.itemDescription = this.itemDocselected.modele.replace("[Docteur]","<b>"+(this.user?.nom?.toUpperCase() + ' '+this.user.prenom?.toUpperCase())+"</b>");
   
    /** On remplace le nom du patient */
    this.itemDescription =  this.itemDescription.replace("[Patient]","<b>"+(this.currentPatient?.beneficiaireNom?.toUpperCase() +' '+this.currentPatient.beneficiairePrenom?.toUpperCase())+"</b>")

    /** On remplace l'unité fonctionnelle */
    this.itemDescription =  this.itemDescription.replace("[UniteFonctionnelle]","<b>"+(this.currentPatient?.adminUniteFonctionnelleLibelle?.toUpperCase())+"</b>")

  }

  formatChamp(champs,ngModelValue,type) {

    let value = ngModelValue;

    if(!type || !value) return;

    if(type?.toLowerCase() == 'date' && ngModelValue) {
      value = moment(ngModelValue).format("DD/MM/YYYY");
    }
     /** On remplace le champs en fonction de l'input selectionné */
     let champARemplacer = "["+champs+"]";
     this.itemDescription =  this.itemDescription.replace(champARemplacer,"<b>"+(value?.toString()?.toUpperCase())+"</b>")
  }
  
  getTypeDocuments() {
    let request = {
      user: this.user.id,
      data: {
      }
    }

    this.busyGet = this.restClient.post('adminTypeDocumentMedical/getByCriteria', request)
      .subscribe(
        res => {
          if (res && res['items']) {
            this.listeTypeDocuments = _.orderBy(res['items'], ['libelle'], ['asc']);

            console.log(" this.itemToSave ",this.itemToSave);
            
            if(this.itemToSave?.typeDocumentMedicalId && this.isUpdate) {
              this.itemToSave.typeDocumentsSelected = _.find(this.listeTypeDocuments, (o) => { return o.id == this.itemToSave?.typeDocumentMedicalId });
              this.getChampsTypeCertificat(); 
            }
          }
          else {
          }
        },
        err => {
        }
      );
  }
  
  getChampsTypeCertificat() {
    let request = {
      user: this.user.id,
      data: {
        typeDocumentMedicalId : this.itemToSave.typeDocumentsSelected?.id,
        "orderField":"id",
        "orderDirection":"asc"
      }
    } 

    this.busyGet = this.restClient.post('adminChampsDocumentMedical/getByCriteria', request)
      .subscribe(
        res => {
          console.log(" res ",res);
          
          if (res && res['items']) {
            this.listeChamps = _.orderBy(res['items'], ['libelle'], ['asc']);

            // if(this.itemToSave?.prioriteId){
            //   this.itemToSave.niveauUrgenceSelected = _.find(this.listNiveauUrgence, (o)=> { return o.id == this.itemToSave?.prioriteId });
            // }

          }
          else {
            this.listeChamps = [];
          }
        },
        err => {
        }
      );
  }
  

  confirmSaveItem(item,sendInValidation) {

    let objToSave = Object.assign({}, item);

    if (!this.consultation?.id) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner une consultation !",
        "bottom",
        "center");
      return;
    }

    if (!this.itemToSave.typeDocumentsSelected) {
      this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner un type de document !",
        "bottom",
        "center");
      return;
    }

    // if (!this.itemToSave.niveauUrgenceSelected) {
    //   this.utilities.showNotification("snackbar-danger", "Veuillez sélectionner le niveau d'urgence !",
    //     "bottom",
    //     "center");
    //   return;
    // }

    // Verifier si tous les champs sont renseignés
    if(this.listeChamps && this.listeChamps.length > 0) {

      let allChampObligatoireRenseigne = _.find(this.listeChamps, (o) => { return !o.nomChamp1 });
      if(allChampObligatoireRenseigne) {
        this.utilities.showNotification("snackbar-danger", "Veuillez renseigner les champs obligatoires !","bottom","center");
        return;
      }
    }

    objToSave.consultationId = this.consultation?.id;
    objToSave.modele = this.itemDescription;
    objToSave.typeDocumentMedicalId = this.itemToSave?.typeDocumentsSelected?.id;

    objToSave.createAndSendInValidation = sendInValidation;

    Swal.fire({
      title: "Certificat médical "+(sendInValidation ? "(envoi en validation)":""),
      text:  "Voulez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0665aa",
      cancelButtonColor: "#aaa4a4",
      confirmButtonText: "Oui",
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.value) {
        this.saveItem(objToSave);
      }
    });
  }

  saveItem(item) {

    this.loading = true;

    let itemAEnregistrer = Object.assign({}, item);

    var request = {
      user: this.user.id,
      datas: [
        itemAEnregistrer 
      ]
    }

    this.busyGet = this.restClient.post('adminCertificat/'+(this.isUpdate ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.loading = false;

          if (!res['hasError']) {
            if (res['items'] && res['items'].length > 0) {

              this.utilities.showNotification("snackbar-success",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
              // this.cancelItem(true);
              this.itemToSave = {};
              this.closeFormulaire();
            }
          } else {
            if (res['status'] && res['status']['message']) {
              this.utilities.showNotification("snackbar-danger",
                this.utilities.formatMsgServeur(res['status']['message']),
                "bottom",
                "center");
            }
          }
        },
        err => {
          this.utilities.showNotification("snackbar-danger", this.utilities.getMessageEndPointNotAvailble(),
            "bottom",
            "center");
          this.loading = false;
        }
      );
  }

  getData(){
    this.getTypeDocuments();
  }

  ngOnInit(): void {
    this.getOldData();
  }

  ngOnChanges(changes: SimpleChanges) {

    console.log(" changes ",changes);
    
    if (changes['currentPatient'] && changes['currentPatient'].currentValue) {
      this.currentPatient = changes['currentPatient'].currentValue;
      this.currentPatient.medecin = this.currentPatient.userNom + " " + this.currentPatient.userPrenom;
    }
  }


}
