<section class="content" >
    <div class="content-block">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header mb-3">
                        <h2><strong> Accueil de dialyse </strong></h2>
                    </div>
                    <mat-tab-group>
                        <mat-tab label="Programme de séance">
                           
                            <ng-template matTabContent>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-12 p-0">
                                            <app-historique-seance-dialyse [isTraiterFiltre]="false"></app-historique-seance-dialyse>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Historique des accueils">
                            <ng-template matTabContent>
                                <div class="body">
                                <div class="row">
                                    <div class="col-12 p-0">
                                        <app-historique-accueils-dialyse></app-historique-accueils-dialyse>
                                    </div>
                                </div>
                                </div>
                            </ng-template>
                        </mat-tab>

                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</section>
