<section class="content">
    <div class="content-block">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header mb-3">
                        <h2><strong>Requêtes de dialyse</strong></h2>
                    </div>

                    <mat-tab-group *ngIf="isHistoFromReception">
                        <mat-tab label="">
                            <ng-template mat-tab-label>
                                En attente de validation
                            </ng-template>

                            <ng-template matTabContent>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-12 p-0">
                                            <app-liste-requetes-dialyse
                                                (requeteSelected)="openModal($event,templateDetails);"
                                                (traiterRequeteSelected)="openModal($event,templateTraiterRequete,true);"
                                                [hasNeedTitleTable]="true" [libelleBouton]="'Détails'"
                                                [hasNeedTitleTable]="false" [hasNeedBtnTraiter]="true"
                                                [refreshItem]="refreshItem"
                                                [statutDialyse]="!isHistoFromReception ? statutEnum?.VALIDE : statutEnum?.EN_ATTENTE_VALIDATION"
                                                [isHistoFromReception]="isHistoFromReception">
                                            </app-liste-requetes-dialyse>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="Historique des requêtes">
                            <ng-template matTabContent>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-12 p-0">
                                            <app-liste-requetes-dialyse
                                                (requeteSelected)="openModal($event,templateDetails);"
                                                (traiterRequeteSelected)="openModal($event,templateTraiterRequete,true);"
                                                [hasNeedTitleTable]="true" [libelleBouton]="'Détails'"
                                                [hasNeedTitleTable]="false" [hasNeedBtnTraiter]="true"
                                                [refreshItem]="refreshItem"></app-liste-requetes-dialyse>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>

                    <mat-tab-group *ngIf="!isHistoFromReception">
                        <mat-tab label="">
                            <ng-template mat-tab-label>
                                Requêtes validées
                            </ng-template>

                            <ng-template matTabContent>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-12 p-0">
                                            <app-liste-requetes-dialyse
                                                (requeteSelected)="openModal($event,templateDetails);"
                                                (traiterRequeteSelected)="openModal($event,templateTraiterRequete,true);"
                                                (requeteForProgramSelected)="openCreateProgramme($event);"
                                                [hasNeedTitleTable]="true" [libelleBouton]="'Détails'"
                                                [hasNeedTitleTable]="false" [hasNeedBtnTraiter]="true"
                                                [refreshItem]="refreshItem"
                                                [hasBtnProgramme]="true"
                                                [statutDialyse]="!isHistoFromReception ? statutEnum?.VALIDE : statutEnum?.EN_ATTENTE_VALIDATION"
                                                [isHistoFromReception]="isHistoFromReception">
                                            </app-liste-requetes-dialyse>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>

                        <mat-tab label="Historique des requêtes">
                            <ng-template matTabContent>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-12 p-0">
                                            <app-liste-requetes-dialyse
                                                (requeteSelected)="openModal($event,templateDetails);"
                                                (traiterRequeteSelected)="openModal($event,templateTraiterRequete,true);"
                                                [hasNeedTitleTable]="true" [libelleBouton]="'Détails'"
                                                [hasNeedTitleTable]="false" [hasNeedBtnTraiter]="true"
                                                [refreshItem]="refreshItem"></app-liste-requetes-dialyse>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>

                    <!-- <div class="body">
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <app-liste-requetes-dialyse (requeteSelected)="openModal($event,templateDetails);" (traiterRequeteSelected)="openModal($event,templateTraiterRequete,true);" [hasNeedTitleTable]="true" [libelleBouton]="'Détails'" [hasNeedTitleTable]="false" [hasNeedBtnTraiter]="true" [refreshItem]="refreshItem" [statutDialyse]="!isHistoFromReception ? statutEnum?.VALIDE : statutEnum?.EN_ATTENTE_VALIDATION" [isHistoFromReception]="isHistoFromReception"></app-liste-requetes-dialyse>
                </div>
              </div>
            </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #templateDetails>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Détails requête de dialyse n° {{currentAccueilSelected?.numeroRequete}}
        </h4>
        <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="modal-body">
        <form class="m-1">
            <div class="row">
                <div class="col-12 mb-2 mt-1">
                    <fieldset class="custom-fieldset">
                        <legend> Infos du patient </legend>
                        <div class="row">
                            <div class="col-12">
                                <app-info-patient-consultation
                                    [idPatient]="currentAccueilSelected?.dataBeneficiaire?.id" [withAssurances]="true">
                                </app-info-patient-consultation>
                            </div>
                        </div>

                    </fieldset>
                </div>

                <div class="col-12 mb-2 mt-1">
                    <fieldset class="custom-fieldset">
                        <legend> Infos requête </legend>

                        <div class="row">
                            <div class="col-md-6">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Type de dialyse </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.dialyseTypeLibelle
                                                    || '-'}} </b></td>
                                        </tr>
                                        <tr>
                                            <td>Abord vasculaire</td>
                                            <td> <b class="value-details">
                                                    {{currentAccueilSelected?.dialyseAbordVasculaireLibelle || '-'}}
                                                </b></td>
                                        </tr>
                                        <tr>
                                            <td>Etablissement de provenance </td>
                                            <td> <b class="value-details">
                                                    {{currentAccueilSelected?.ucEtablissementEvacuationLibelle || '-'}}
                                                </b></td>
                                        </tr>
                                        <tr>
                                            <td>Médecin referent </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.medecinReferent}}
                                                </b></td>
                                        </tr>
                                        <tr>
                                            <td>Jour de dialyse </td>
                                            <td> <b class="value-details">
                                                    {{currentAccueilSelected?.dialyseJourLibelle}} </b></td>
                                        </tr>

                                        <tr>
                                            <td>Période de dialyse </td>
                                            <td> <b class="value-details">
                                                    {{currentAccueilSelected?.dialysePeriodeLibelle}} </b></td>
                                        </tr>


                                        <tr>
                                            <td>Statut</td>
                                            <td> <b class="value-details"
                                                    [class.isReject]="currentAccueilSelected?.statut?.toLowerCase() == 'rejete'"
                                                    [class.isValide]="currentAccueilSelected?.statut?.toLowerCase() == 'valider' || currentAccueilSelected?.statut?.toLowerCase() == 'valide'">
                                                    {{currentAccueilSelected?.statut}} </b></td>
                                        </tr>

                                        <tr *ngIf="currentAccueilSelected?.valderPar">
                                            <td>Validé par </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.valderPar}} </b>
                                            </td>
                                        </tr>

                                        <tr *ngIf="currentAccueilSelected?.validatedAt">
                                            <td>Validé le </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.validatedAt}} </b>
                                            </td>
                                        </tr>

                                        <tr *ngIf="currentAccueilSelected?.motif">
                                            <td>Motif décision </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.motif}} </b></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div class="col-md-6">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td>Téléphone </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.contact | mask:'00 00 00 00 00'
                                                    || '-'}} </b></td>
                                        </tr>
                                        <tr>
                                            <td>Nombre de séance </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.nbreSeance |
                                                    moneyFormat}} </b></td>
                                        </tr>
                                        <tr>
                                            <td>Est-ce une urgence ? </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.isUrgence ? 'Oui' :
                                                    'Non'}} </b></td>
                                        </tr>

                                        <tr>
                                            <td>Est-ce ittérative ? </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.isIterative ? 'Oui' :
                                                    'Non'}} </b></td>
                                        </tr>

                                        <tr>
                                            <td>Patient diabétique ?</td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.isDiabete ? 'Oui' :
                                                    'Non'}} </b></td>
                                        </tr>
                                        <tr>
                                            <td>Date 1er </td>
                                            <td> <b class="value-details"> {{currentAccueilSelected?.dateFirstEer}} </b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Enregistré par </td>
                                            <td> <b class="value-details">
                                                    {{ (currentAccueilSelected?.enregistrePar || currentAccueilSelected?.enregistrerPar ) | uppercase}} </b></td>
                                        </tr>

                                      

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </fieldset>
                </div>

            </div>
        </form>
    </div>

    <!-- <div class="modal-footer modal-footer-custom">

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

            <button type="button" (click)="confirmSaveItem(itemToSave)" class="btn btn-md custom_btn_primary pull-right"
                [disabled]="!itemToSave.libelle" [class.auth-spinner]="loading" [disabled]="loading"> {{itemToSave?.id ?
                'Modifier' : "Enregistrer"}}</button>

            <button type="button" class="btn btn-md btn-cancel pull-right" (click)="modalRef.hide()">Annuler</button>
        </div>
    </div> -->
</ng-template>

<ng-template #templateTraiterRequete>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            Traitement requête de dialyse n° {{currentAccueilSelected?.numeroRequete}}
        </h4>

        <button mat-icon-button class="pull-right" (click)="modalRef.hide()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>

    </div>
    <div class="modal-body">
        <form class="m-1">
            <div class="row">
                <div class="col-12 mb-2 mt-1">
                    <div class="col-md-12 col-sm-12 mb-3">
                        <label>Décision:
                            <span class="required_champs">*</span>
                        </label>

                        <select class="form-control" name="Décision" [(ngModel)]="itemPriseDecision.decision">
                            <option value=""></option>
                            <option value="Valider">Valider</option>
                            <option value="Rejeter">Rejeter</option>
                        </select>
                    </div>
                    <div class="col-12 mb-3">
                        <label>Motif :
                            <span *ngIf="itemPriseDecision?.decision != 'Valider' " class="required_champs">*</span>
                        </label>
                        <textarea rows="7" class="form-control custom-input" name="avis"
                            [(ngModel)]="itemPriseDecision.libelle">
                              </textarea>
                    </div>

                </div>
            </div>
        </form>
    </div>

    <div class="modal-footer modal-footer-custom">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <button type="button" (click)="confirmTraiterDemande()" class="btn btn-md custom_btn_primary pull-right"
                [disabled]="!itemPriseDecision.libelle" [class.auth-spinner]="loading" [disabled]="loading">
                Enregistrer</button>

            <button type="button" class="btn btn-md btn-cancel pull-right" (click)="modalRef.hide()">Fermer</button>
        </div>
    </div>
</ng-template>
