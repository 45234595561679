<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ title | titlecase }}
  </h4>

  <button mat-icon-button class="pull-right" (click)="bsModalRef.hide()" aria-label="Close dialog">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="modal-body" [ngBusy]="{
    busy: busySave,
    message: 'Veuillez patienter ...',
    backdrop: false
  }">
  <ng-container>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 mb-1">
            <label>Login :
              <span class="required_champs">*</span>
            </label>
            <input disabled class="form-control" [(ngModel)]="itemToSave.login" type="text" name="login" id="" />
          </div>

          <div class="col-12 mb-1">
            <label>Ancien mot de passe :
              <span class="required_champs">*</span>
            </label>
            <input class="form-control" [(ngModel)]="itemToSave.password" type="password" name="password" id="" />
          </div>

          <div class="col-12 mb-1">
            <label>Nouveau mot de passe :
              <span class="required_champs">*</span>
            </label>
            <input class="form-control" [(ngModel)]="itemToSave.newPassword" type="password" name="newPassword" id="" />
          </div>
          <div class="col-12 mb-1">
            <label>Confirmer mot de passe :
              <span class="required_champs">*</span>
            </label>
            <input class="form-control" [(ngModel)]="confirmnewPassword" type="password" name="confirmnewPassword"
              id="" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-footer modal-footer-custom">
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <button type="button" (click)="confirmSaveItem(itemToSave)" class="btn btn-md custom_btn_primary pull-right"
      [class.auth-spinner]="loading" [disabled]="loading">
      {{ itemToSave?.id ? "Enregistrer" : "Enregistrer" }}
    </button>

    <button type="button" class="btn btn-md btn-cancel pull-right" (click)="bsModalRef.hide()">
      Annuler
    </button>
  </div>
</div>