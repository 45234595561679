import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  TemplateRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { AuthService } from "src/app/core/service/auth.service";
import { RightSidebarService } from "src/app/core/service/rightsidebar.service";
import { LanguageService } from "src/app/core/service/language.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { User } from "src/app/core/models/user";
import { ModalForgotPasswordComponent } from "src/app/authentication/signin/modal-forgot-password/modal-forgot-password.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalUpdatePasswordComponent } from "src/app/authentication/signin/modal-update-password/modal-update-password.component";
import { RestClientService } from "src/app/core/service/rest-client.service";
import { Subscription } from "rxjs";
const document: any = window.document;
import { interval } from 'rxjs';
import * as moment from 'moment';
import { UtilitiesService } from "src/app/core/service/utilities.service";
import { PriseDeDecisionWorkflowComponent } from "src/app/shared/components/prise-de-decision-workflow/prise-de-decision-workflow.component";
import { enumCodeCircuitValidation, enumOrigineOuvertureModalForNotification, enumTypeRetour } from "src/app/core/enumerator/enumerator";
import * as _ from "lodash";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit {
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  modalRef?: BsModalRef;
  currentUser: User = JSON.parse(localStorage.getItem('currentUser'))
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  activeUniteFonctionnelle: any = {}
  currentTotalItems: any = 0;
  listeNotifications: any = [];
  listeActesExamens : any = [];
  busyGet : Subscription;
  user: any = {};
  codeCirucit : any = {};
  acteSelected : any = {};
  isInterpretationRadioOrLabo : boolean;

  subscribeEcouteNotification? : Subscription;
  requestRefreshNotifications?: Subscription;
  itemToSave : any = {};
  isOpen : boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private modalService: BsModalService,
    private restClient: RestClientService,
    private utilities: UtilitiesService
  ) {
    super();
    this.user = this.authService.currentUserValue;
    this.codeCirucit = enumCodeCircuitValidation;
  }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.svg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.svg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.svg", lang: "de" },
  ];

  notifications: any[] = [
    // {
    //   message: "Please check your mail",
    //   time: "14 mins ago",
    //   icon: "mail",
    //   color: "nfc-green",
    //   status: "msg-unread",
    // },
    // {
    //   message: "New Patient Added..",
    //   time: "22 mins ago",
    //   icon: "person_add",
    //   color: "nfc-blue",
    //   status: "msg-read",
    // },
    // {
    //   message: "Your leave is approved!! ",
    //   time: "3 hours ago",
    //   icon: "event_available",
    //   color: "nfc-orange",
    //   status: "msg-read",
    // },
    // {
    //   message: "Lets break for lunch...",
    //   time: "5 hours ago",
    //   icon: "lunch_dining",
    //   color: "nfc-blue",
    //   status: "msg-read",
    // },
    // {
    //   message: "Patient report generated",
    //   time: "14 mins ago",
    //   icon: "description",
    //   color: "nfc-green",
    //   status: "msg-read",
    // },
    // {
    //   message: "Please check your mail",
    //   time: "22 mins ago",
    //   icon: "mail",
    //   color: "nfc-red",
    //   status: "msg-read",
    // },
    // {
    //   message: "Salary credited...",
    //   time: "3 hours ago",
    //   icon: "paid",
    //   color: "nfc-purple",
    //   status: "msg-read",
    // },
  ];

  ngOnInit() {

    this.refreshDataNotification();

    let uf = JSON.parse(localStorage.getItem('currentUser'))?.uniteFonctionnelles;

    if(uf) {
      this.activeUniteFonctionnelle = uf.filter(auf => auf.idUniteFonctionnelle == JSON.parse(localStorage.getItem('currentUser')).uniteFoncActiveId)[0]
    }
    this.config = this.configService.configData;
    const userRole = this.authService.currentUserValue.role;
    this.userImg = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).photo ?? 'assets/images/custom/user-ano.jpeg' : '';

    this.langStoreValue = localStorage.getItem("lang");
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = "assets/images/flags/us.svg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    this.subscribeEcouteNotification = this.utilities.currentStatutToRefreshNotification.subscribe(currentData => {
      if(currentData && currentData == enumOrigineOuvertureModalForNotification?.validation_screen) {
        // Cela suppose qu'on a fait une validation depuis l'interface de validation sans passer par le bouton traiter de notification
        this.getNotifications();
      }
    });
  }

//   openTraiterDemande(data) {
//     let initialState = {
//         listeItemByOrigine: data.medicaments || [],
//         itemPriseDecisionByOrigine: {...data?.dataSortie},
//         origine : data?.code
//         //class: 'details-offres modal-lg'
//     }
//     this.modalService.show(PriseDeDecisionWorkflowComponent, {
//         animated: true,
//         keyboard: false, 
//         backdrop: 'static',
//         ignoreBackdropClick: true, initialState, class: 'gray modal-width-80'
//     })
//     this.modalService.onHide.subscribe((result) => {
//         if (result === 'Yes') {
//             this.getNotifications()
//         }
//     })
// }

openModal(data: any, template: TemplateRef<any>) {

  let config = {backdrop: true, ignoreBackdropClick: true};

  if (data) {
    // Lorsque nous sommes en modification
    this.itemToSave = {
      listeItemByOrigine: data.medicaments || [],
      itemPriseDecisionByOrigine: null,
      origine : data?.code
    }
 
    if(data?.code == enumCodeCircuitValidation.PHARMACIE_SORTIE_DIVERSE) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataSortie}
    }

    if(data?.code == enumCodeCircuitValidation.PHARMACIE_TRANSFERT_MEDICAMENT) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataTransfert}
    }

    if(data?.code == enumCodeCircuitValidation.PHARMACIE_LIVRAIONS_MEDICAMENT) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataLivraison}
    }

    if(data?.code == enumCodeCircuitValidation.PHARMACIE_BON_COMMANDE) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataCommande}
    }

    if(data?.code == enumCodeCircuitValidation.CERTIFICAT_MEDICAL) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataCertificat}
    }

    
    if(data?.code == enumCodeCircuitValidation.DMD_SERVICE) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataService}
      this.itemToSave.listeItemByOrigine = data?.dataService.medicaments;
    }

    // if(data?.code == enumCodeCircuitValidation.PHARMACIE_INVENTAIRE) {
    //   this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataInventaire}
    //   this.itemToSave.listeItemByOrigine = [];
    // }

    if(data?.code == enumCodeCircuitValidation.GC_DEMANDE_REMISE) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataRemise}
      // this.itemToSave.listeItemByOrigine = [];
    }

    if(data?.code == enumCodeCircuitValidation.PHARMACIE_INVENTAIRE) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataInventaire}
      this.itemToSave.listeItemByOrigine = [];
    }

    if(data?.code == enumCodeCircuitValidation.JOURNAL || data?.code == enumCodeCircuitValidation.JOURNAL_PRINCIPAL) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data}
      this.itemToSave.listeItemByOrigine = data?.dataJournal?.datasReglement || data?.dataJournal?.datasJournal;
    }

    if(data?.code == enumCodeCircuitValidation.HONNORAIRE) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataHonoraire}
      this.itemToSave.listeItemByOrigine = data?.dataHonoraire?.vacations;
 
      let listeMois = this.utilities.getMonth();
      this.itemToSave.itemPriseDecisionByOrigine.moisLetter = _.find(listeMois, (o) => { return o.indice == this.itemToSave.itemPriseDecisionByOrigine?.mois })?.libelle;
      this.itemToSave.itemPriseDecisionByOrigine.identiteVacataire = this.itemToSave.itemPriseDecisionByOrigine.datasVacataire[0]?.userNom + ' '+this.itemToSave.itemPriseDecisionByOrigine.datasVacataire[0]?.userPrenom;
    }

    if(data?.code == enumCodeCircuitValidation.REMBOURSEMENT_ACTE_EXTERNES) {
      this.itemToSave.itemPriseDecisionByOrigine = {...data?.dataRemboursement}
      this.itemToSave.listeItemByOrigine = data?.dataRemboursement?.documents;

      console.log(" data notification",data);
      
      if(data.dataRemboursement?.typeRemboursement?.toLowerCase() == enumTypeRetour.VENTE_DIRECTE?.toLowerCase()) {
        this.itemToSave.itemPriseDecisionByOrigine.medicaments = data.dataRemboursement?.dataRetour?.retourVentesDirectes;

        this.itemToSave.itemPriseDecisionByOrigine.nomPrenomPatient = data.dataRemboursement?.dataRetour?.dataVenteDirecte?.nomClient;
      
        this.itemToSave.itemPriseDecisionByOrigine.dataReglement = {
          numeroReglement : data.dataRemboursement?.dataRetour?.dataVenteDirecte?.numeroVente,
          montantARegler : data.dataRemboursement?.dataRetour?.dataVenteDirecte?.montant
        }
      }

      if(data.dataRemboursement?.typeRemboursement?.toLowerCase() == enumTypeRetour.VENTE_ACTE_EXTERNES?.toLowerCase()) {
        this.itemToSave.itemPriseDecisionByOrigine.medicaments = data.dataRemboursement?.dataRetour?.retourActesExternes;

        this.itemToSave.itemPriseDecisionByOrigine.nomPrenomPatient = data.dataRemboursement?.dataRetour?.dataVenteActeExterne?.nomClient;
      
        this.itemToSave.itemPriseDecisionByOrigine.dataReglement = {
          numeroReglement : data.dataRemboursement?.dataRetour?.dataVenteActeExterne?.numeroVente,
          montantARegler : data.dataRemboursement?.dataRetour?.dataVenteActeExterne?.montant
        }
      }

      if(data.dataRemboursement?.typeRemboursement?.toLowerCase() == enumTypeRetour.VENTE_BON?.toLowerCase()) {
        console.info('data retour vente sur bon: ',data)
        if(data.dataRemboursement?.dataRetour?.retourVenteBon) {
          data.dataRemboursement?.dataRetour?.retourVenteBon.forEach(element => {
            element.nomMedivament = element.pharmacieMedicamentLibelle;
            element.prixUnitaireVente = (element.prixUnitaireVente) || (element.prixUnitaire)
          });
        }
        this.itemToSave.itemPriseDecisionByOrigine.medicaments = data.dataRemboursement?.dataRetour?.retourVenteBon;

        this.itemToSave.itemPriseDecisionByOrigine.nomPrenomPatient = data.dataRemboursement?.dataRetour?.datasVenteBon[0]?.beneficiaireNom+" "+data.dataRemboursement?.dataRetour?.datasVenteBon[0]?.beneficiairePrenom;
        this.itemToSave.itemPriseDecisionByOrigine.matriculePatient = data.dataRemboursement?.dataRetour?.datasVenteBon[0]?.matriculeBeneficiaire;

        this.itemToSave.itemPriseDecisionByOrigine.dataReglement = {
          numeroReglement : data.dataRemboursement?.dataRetour?.datasVenteBon[0]?.numeroVente,
          // montantARegler : data.dataRemboursement?.dataRetour?.datasVenteBon[0]?.partPatient,
          montantARegler:data.dataRemboursement.dataRetour.partClient
          // montantARegler : data.dataRemboursement?.dataRetour?.datasVenteBon[0]?.partClient
        }
      }


    }

    if(data?.code == enumCodeCircuitValidation.RES_RADIO) {
       // On recupere les elements avec les images de l'accueil
       this.getActeEnInterpretation(data?.idEntite);
    }

    if(data?.code == enumCodeCircuitValidation.RADIO) {
      // On recupere les elements avec les images de l'accueil
      this.isInterpretationRadioOrLabo = true;
      this.getAccueilRadioEnInterpretation(data?.idEntite);
   }

   if(data?.code == enumCodeCircuitValidation.LABO) {
    // On recupere les elements avec les images de l'accueil
    this.isInterpretationRadioOrLabo = true;
    this.getAccueilLaboEnInterpretation(data?.idEntite);
   }


  }
  this.modalRef = this.modalService.show(template,Object.assign({},config, { class: 'modal-lg modal-width-90' }));
}

selectedActe(acte) {
  this.acteSelected = {...acte};
}

getActeEnInterpretation(id?) {

  this.listeActesExamens = [];

  let request = {
    user: this.user.id,
    data: {
      accueilId: id,
    },
    index: 0,
    size: 100,
  };

  this.busyGet = this.restClient
    .post("accueil/getAccueilActeRadioInterpretation", request)
    .subscribe(
      (res) => {
        console.log(" res labo ",res); 
        
        if (res && res["items"]) {
          this.listeActesExamens = res["items"];
          this.selectedActe(this.listeActesExamens[0]);
        }
      },
      (err) => { }
    );
}

getAccueilRadioEnInterpretation(id?) {

  this.listeActesExamens = [];

  let request = {
    user: this.user.id,
    data: {
      accueilId: id,
    },
    index: 0,
    size: 100,
  };

  this.busyGet = this.restClient
    .post("exCompResultatExamenActeExterne/getResultatExamenConsultationRadio", request)
    .subscribe(
      (res) => {
        if (res && res["items"]) {
          this.listeActesExamens = res["items"];
          this.selectedActe(this.listeActesExamens[0]);
        }
      },
      (err) => { }
    );
}

getAccueilLaboEnInterpretation(id?) {

  this.listeActesExamens = [];

  let request = {
    user: this.user.id,
    data: {
      id: id,
    },
    index: 0,
    size: 100,
  };

  this.busyGet = this.restClient
    .post("exCompResultatExamenActeExterne/getResultatExamenConsultationLabo", request)
    .subscribe(
      (res) => {
        if (res && res["items"]) {
          this.listeActesExamens = res["items"];
          this.selectedActe(this.listeActesExamens[0]);
        }
      },
      (err) => { }
    );
}

closeItemTraitement() {
  this.modalRef.hide();
  this.getNotifications();
}

  getNotifications() {

    let request = {
      user: this.user.id,
      data: {
        "orderField": "createdAt",
        "orderDirection": "desc",
        destinataireId: this.user?.id,
        isOuvert: false
      },
    }

    this.restClient.post('adminNotification/getByCriteria', request)
      .subscribe(
        res => {
          // console.log(" res ", res);

          if (res && res['items']) {
            this.listeNotifications = res['items'];

            console.log("listeNotifications",this.listeNotifications);
            

            if (this.listeNotifications?.length != this.currentTotalItems && (this.listeNotifications?.length - this.currentTotalItems) > 0) {
              /** On affiche un message montrant le nombre de nouveaux message arrivé */
              let messageNotification = "🛎️ Vous avez " + (this.listeNotifications?.length - this.currentTotalItems) + " notification(s) en attente !"
              this.utilities.showNotification("snackbar-danger", messageNotification, "bottom", "right", true);
            }

            this.currentTotalItems = res['count'];
          }
          else {
            this.listeNotifications = [];
            this.currentTotalItems = 0;
          }
        },
        err => {
        }
      );
  }

  getDuree(createdAt?) {
    moment.locale('fr');
    let dateCreation: any;
    let dateJourMonthYearFormat: any;
    let dateHourMinSecFormat: any;
    let dateFormat = createdAt.split(' ');

    if (dateFormat && dateFormat.length > 0) {
      dateJourMonthYearFormat = dateFormat[0].split('/');
      dateHourMinSecFormat = dateFormat[1].split(':');
    } else {
      return
    }

    dateCreation = new Date(dateJourMonthYearFormat[2], dateJourMonthYearFormat[1] - 1, dateJourMonthYearFormat[0],
      dateHourMinSecFormat[0], dateHourMinSecFormat[1], dateHourMinSecFormat[2]);

    let duration = moment().diff(moment(dateCreation), 'minutes');
    let message: any = "Il y a " + duration + " minute" + (duration > 1 ? "s" : "");

    if (duration > 60 * 24) { // superieur a un jour
      // Je convertir en jour
      duration = moment().diff(moment(dateCreation), 'days');
      message = "Il y a " + duration + " jour" + (duration > 1 ? "s" : "");
    } else {
      //Si c'est superieur à 60 minutes, on converti en hh
      if (duration > 60) {
        duration = moment().diff(moment(dateCreation), 'hour');
        message = "Il y a " + duration + " heure" + (duration > 1 ? "s" : "");
      }
    }

    return message;

  }

  /** On implemente un timer qui va tourner par intervalle de temps */
  refreshDataNotification() {
    this.getNotifications();
    this.requestRefreshNotifications = interval(2 * 60 * 1000) /* On actualise chaque 2 minutes */
      .subscribe(i => {
        this.getNotifications()
      })
  }

  /** Marquer le isOuvert pour les notifications qui n'on pas besoin de traitement */
  updateIsOuverNotification() {
    /** Recuperer ceux qui n'ont pas besoin de traitement */ 
    let listeNotificationSansTraitement = _.filter(this.listeNotifications, (o) => { return !o.hasValidation; });

    console.log(" listeNotificationSansTraitement ",listeNotificationSansTraitement);
    
    if(listeNotificationSansTraitement && listeNotificationSansTraitement?.length > 0) {

      listeNotificationSansTraitement.forEach(notif => {
          notif.isOuvert = true
      });
      var request = {
        user: this.user.id,
        datas: listeNotificationSansTraitement,
      };

      this.restClient
      .post("adminNotification/update", request)
      .subscribe(
        (res) => {

          if (!res["hasError"]) {
            if (res["items"] && res["items"].length > 0) {
              this.getNotifications();
              // this.utilities.showNotification("snackbar-success",this.utilities.formatMsgServeur(res["status"]["message"]),"bottom", "center");
            }
          } 
          else {
            if (res["status"] && res["status"]["message"]) {
              // this.utilities.showNotification("snackbar-danger",this.utilities.formatMsgServeur(res["status"]["message"]),"bottom","center");
            }
          }
        },
        (err) => {
          // this.utilities.showNotification(
          //   "snackbar-danger",
          //   this.utilities.getMessageEndPointNotAvailble(),
          //   "bottom",
          //   "center"
          // );
        }
      );

    }
    
  }

  openToogle(isOpen) {

    console.log(" isOpen ",isOpen);
    
    if(!isOpen) {
      // En ce moment le modal vient de se fermer je marque estOuvert true 
      setTimeout(() => {
        this.updateIsOuverNotification();
      }, 2000);
    }
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }
  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }

  updatePassword() {
    let modal: any
    modal = ModalUpdatePasswordComponent
    this.modalRef = this.modalService.show(modal, {
      id: 1,
      class: 'modal-custom',
      // data:boulangerie
    });
    // if (data) this.modalRef.content.currentData = data;
    this.modalRef.onHide.subscribe((res) => {
      console.log('hidden');
      // this.getData();
    });
  }

  ngOnDestroy() {
    if (this.subscribeEcouteNotification) {
      this.subscribeEcouteNotification.unsubscribe();
    }
  }
}
