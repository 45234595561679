<div>
  <!-- Left Sidebar -->
  <aside
    id="leftsidebar"
    class="sidebar"
    (mouseenter)="mouseHover($event)"
    (mouseleave)="mouseOut($event)"
  >
    <!-- Menu -->
    <div class="menu">
      <ul
        class="list"
        [ngStyle]="{
          position: 'relative',
          'max-height': listMaxHeight + 'px',
          'max-width': listMaxWidth + 'px'
        }"
        [perfectScrollbar]
      >
        <!-- <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img src={{userImg}} class="img-circle user-img-circle" alt="User Image" />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name">{{userFullName}} </div>
            <div class="profile-usertitle-job ">{{userType}} </div>
          </div>
        </li> -->
        <!-- Top Most level menu -->
        <li
          [class.active]="
            level1Menu === sidebarItem.moduleName &&
            sidebarItem.submenu.length != 0
          "
          *ngFor="let sidebarItem of sidebarItems"
          [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'"
        >
          <div class="header" *ngIf="sidebarItem.groupTitle === true">
            {{ sidebarItem.title | translate }}
          </div>
          <a
            [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null"
            *ngIf="!sidebarItem.groupTitle"
            appAppAccess
            appAccess="{{ sidebarItem?.code || '' }}"
            [ngClass]="[sidebarItem.class]"
            (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
            class="menu-top"
          >
            <!-- <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i> -->

            <i [ngClass]="'fa fa-' + [sidebarItem.iconType]"></i>

            <span class="hide-menu">{{ sidebarItem.title | translate }} </span>
            <span
              *ngIf="sidebarItem.badge != ''"
              [ngClass]="[sidebarItem.badgeClass]"
              >{{ sidebarItem.badge }}</span
            >
          </a>
          <!-- First level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li
              [ngClass]="
                level2Menu === sidebarSubItem.moduleName ? 'activeSub' : ''
              "
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [routerLinkActive]="
                sidebarSubItem.submenu.length > 0 ? '' : 'active'
              "
              appAppAccess
              appAccess="{{ sidebarSubItem?.code || '' }}"
            >
              <a
                [routerLink]="
                  sidebarSubItem.submenu.length > 0
                    ? null
                    : [sidebarSubItem.path]
                "
                (click)="callLevel2Toggle($event, sidebarSubItem.moduleName)"
                [ngClass]="[sidebarSubItem.class]"
              >
                <!-- <i [ngClass]="'fa fa-'+[sidebarSubItem.iconType]" *ngIf="sidebarSubItem.iconType"></i>  -->
                {{ sidebarSubItem.title | translate }}
                <i
                  [ngClass]="'fa fa-chevron-right pull-right'"
                  *ngIf="
                    sidebarSubItem.submenu && sidebarSubItem.submenu.length > 0
                  "
                ></i>
              </a>
              <!-- Second level menu -->
              <ul class="ml-menu-2" *ngIf="sidebarSubItem.submenu.length > 0">
                <li
                  *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="
                    level3Menu === sidebarSubsubItem.moduleName
                      ? 'activeSubSub'
                      : ''
                  "
                  [routerLinkActive]="
                    sidebarSubsubItem.submenu.length > 0 ? '' : 'active'
                  "
                  appAppAccess
                  appAccess="{{ sidebarSubsubItem?.code || '' }}"
                >
                  <a
                    [routerLink]="
                      sidebarSubsubItem.submenu.length > 0
                        ? null
                        : [sidebarSubsubItem.path]
                    "
                    (click)="
                      callLevel3Toggle($event, sidebarSubsubItem.moduleName)
                    "
                    [ngClass]="[sidebarSubsubItem.class]"
                  >
                    {{ sidebarSubsubItem.title | translate }}
                    <i
                      [ngClass]="'fa fa-chevron-right pull-right'"
                      *ngIf="
                        sidebarSubsubItem.submenu &&
                        sidebarSubsubItem.submenu.length > 0
                      "
                    ></i>
                  </a>

                  <ul
                    class="ml-menu-3"
                    *ngIf="sidebarSubsubItem.submenu.length > 0"
                  >
                    <li
                      *ngFor="
                        let sidebarSubsubItemTrois of sidebarSubsubItem.submenu
                      "
                      [ngClass]="
                        level3Menu === sidebarSubsubItemTrois.moduleName
                          ? 'activeSubSub'
                          : ''
                      "
                      [routerLinkActive]="
                        sidebarSubsubItemTrois.submenu.length > 0
                          ? ''
                          : 'active'
                      "
                      appAppAccess
                      appAccess="{{ sidebarSubsubItemTrois?.code || '' }}"
                    >
                      <a
                        [routerLink]="
                          sidebarSubsubItemTrois.submenu.length > 0
                            ? null
                            : [sidebarSubsubItemTrois.path]
                        "
                        (click)="
                          callLevel3Toggle(
                            $event,
                            sidebarSubsubItemTrois.moduleName
                          )
                        "
                        [ngClass]="[sidebarSubsubItemTrois.class]"
                      >
                        {{ sidebarSubsubItemTrois.title | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <!-- <li>
          <a class="" (click)="logout()" class="menu-top">
            <i class="material-icons-two-tone">power_settings_new</i>
            <span class="hide-menu">Déconnexion
            </span>
          </a>
        </li> -->
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
