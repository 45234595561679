<ng-container>
    <div class="header-wizard-part">
        <span [class.active]="stepWizard == 1" (click)="stepWizard = 1"> <small> 1 </small> Identité </span>
        <span [class.active]="stepWizard == 2" (click)="stepWizard = 2"> <small> 2 </small> Infos supplémentaires
        </span>
        <span [class.active]="stepWizard == 3" (click)="stepWizard = 3"> <small> 3 </small> Personne à contacter </span>
    </div>

    <form class="form-part" [ngBusy]="{busy: busyGet, message: 'Veuillez patienter ...', backdrop: false}">

        <ng-container *ngIf="stepWizard == 1">

            <div class="row">

                <div class="col-md-2 col-sm-12 mb-3">
                    <label>Matricule :
                        <!-- <span class="required_champs">*</span> -->
                    </label>
                    <input class="form-control custom-input" [disabled]='true' placeholder="Matricule"
                        autocomplete="off" [(ngModel)]="itemToSave.matriculeBeneficiaire" name="matriculeBeneficiaire"
                        type="text">
                </div>

                <div class="col-md-2 col-sm-12 mb-3">
                    <label>Civilité :
                        <span class="required_champs">*</span>
                    </label>
                    <!-- <input class="form-control custom-input" placeholder="Libellé" autocomplete="off"
                        [(ngModel)]="itemToSave.libelle" name="libelle"  type="text"> -->

                    <select class="form-select" name="civilite" [(ngModel)]="itemToSave.civilite">
                        <option value=""></option>
                        <option value="Mr">Monsieur</option>
                        <option value="Madame">Madame</option>
                        <option value="Mlle">Madémoiselle</option>
                    </select>
                </div>

                <div class="col-md-2 col-sm-12 mb-3">
                    <label>Nom :
                        <span class="required_champs">*</span>
                    </label>
                    <input class="form-control custom-input" placeholder="Nom" autocomplete="off"
                        [(ngModel)]="itemToSave.nom" name="nom" type="text">
                </div>


                <div class="col-md-4 col-sm-12 mb-3">
                    <label>Prénoms :
                        <span class="required_champs">*</span>
                    </label>
                    <input class="form-control custom-input" placeholder="Prénoms" autocomplete="off"
                        [(ngModel)]="itemToSave.prenom" name="prenom" type="text">
                </div>

                <div class="col-md-2 col-sm-12 mb-3">
                    <label>Genre :
                        <span class="required_champs">*</span>
                    </label>

                    <select class="form-select" name="sexe" [(ngModel)]="itemToSave.sexe">
                        <option value=""></option>
                        <option value="M" [disabled]="itemToSave.civilite == 'Madame' || itemToSave.civilite == 'Mlle'">
                            Masculin</option>
                        <option value="F" [disabled]="itemToSave.civilite == 'Mr' ">Féminin</option>
                    </select>
                </div>
            </div>


            <div class="row">

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Date de naissance :
                        <span class="required_champs">*</span>
                    </label>
                    <input class="form-control custom-input" placeholder="Date de naissance" autocomplete="off"
                        [(ngModel)]="itemToSave.dateNais" bsDatepicker [maxDate]="dateActuelle"
                        [bsConfig]="{dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' }" name="dateNais"
                        type="text">
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Lieu de naissance :
                    </label>
                    <input class="form-control custom-input" placeholder="Lieu de naissance" autocomplete="off"
                        [(ngModel)]="itemToSave.lieuNais" name="lieuNais" type="text">
                </div>


                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Profession:
                        <span class="required_champs">*</span>
                    </label>
                    <!-- <select class="form-control custom_input" id="professionSelected"
                        [(ngModel)]="itemToSave.professionSelected" name="professionSelected">
                        <option value="">-- Choisissez --</option>
                        <option *ngFor="let item of listProfessions" [ngValue]="item">
                            {{ item.libelle }}
                        </option>
                    </select> -->

                    <ng-select [(ngModel)]="itemToSave.professionSelected" placeholder="Choisissez"
                        name="professionSelected" id="professionSelected" [multiple]="false" [closeOnSelect]="true">
                        <ng-option *ngFor="let item of listProfessions" [value]="item">
                            {{item?.libelle}}
                        </ng-option>
                    </ng-select>

                </div>


                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Groupe sanguin :
                    </label>

                    <select class="form-select custom_input" id="groupeSanguinelected"
                        [(ngModel)]="itemToSave.groupeSanguinelected" name="groupeSanguinelected">
                        <option value="">-- Choisissez --</option>
                        <option *ngFor="let item of listGroupesSanguin" [ngValue]="item">
                            {{ item.libelle }}
                        </option>
                    </select>
                </div>

            </div>


            <div class="row">
                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Contact :
                        <span class="required_champs">*</span>
                    </label>
                    <input class="form-control custom-input" placeholder="Contact" autocomplete="off"
                        [(ngModel)]="itemToSave.contact" name="contact" type="text">
                </div>


                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Email :
                    </label>
                    <input class="form-control custom-input" placeholder="Email" autocomplete="off"
                        [(ngModel)]="itemToSave.email" name="email" type="email">
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Catégorie client :
                        <span class="required_champs">*</span>
                    </label>

                    <select class="form-select custom_input" id="categorieClientSelected"
                        [(ngModel)]="itemToSave.categorieClientSelected" name="categorieClientSelected">
                        <option value="">-- Choisissez --</option>
                        <option *ngFor="let item of listCategorieClients" [ngValue]="item">
                            {{ item.libelle }}
                        </option>
                    </select>

                    <!-- <input class="form-control custom-input" placeholder="Libellé" autocomplete="off"
                        [(ngModel)]="itemToSave.libelle" name="libelle" type="text"> -->
                </div>


                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Type bénéficiaire :
                        <span class="required_champs">*</span>
                    </label>

                    <select class="form-select custom_input" id="typeBeneficiaireSelected"
                        [(ngModel)]="itemToSave.typeBeneficiaireSelected" name="typeBeneficiaireSelected">
                        <option value="">-- Choisissez --</option>
                        <option *ngFor="let item of listTypeBeneficiaires" [ngValue]="item">
                            {{ item.libelle }}
                        </option>
                    </select>

                    <!-- <input class="form-control custom-input" placeholder="Libellé" autocomplete="off"
                        [(ngModel)]="itemToSave.libelle" name="libelle" type="text"> -->
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Assurance : </label>

                    <!-- <select class="form-control custom_input" id="assuranceSelected"
                        [(ngModel)]="itemToSave.assuranceSelected" name="assuranceSelected">
                        <option value="">-- Choisissez --</option>
                        <option *ngFor="let item of listAssurances" [ngValue]="item">
                            {{ item.raisonSociale }}
                        </option>
                    </select> -->

                    <ng-select [(ngModel)]="itemToSave.assuranceSelected" placeholder="Choisissez"
                    name="assuranceSelected" id="assuranceSelected" [multiple]="false" [closeOnSelect]="true">
                    <ng-option *ngFor="let item of listAssurances" [value]="item">
                        {{ item?.raisonSociale }}
                    </ng-option>
                </ng-select>

                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Taux de Prise en char.(%): </label>
                    <input class="form-control custom-input" placeholder="Taux de PC" autocomplete="off"
                        [(ngModel)]="itemToSave.tauxPc" name="taux" type="text" mask="separator" maxlength="3">
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Ethnie :
                        <span class="required_champs">*</span>
                    </label>

                    <select class="form-select custom_input" id="ethnieSelected"
                        [(ngModel)]="itemToSave.ethnieSelected" name="ethnieSelected">
                        <option value="">-- Choisissez --</option>
                        <option *ngFor="let item of listEthnies" [ngValue]="item">
                            {{ item.libelle }}
                        </option>
                    </select>

                </div>

            </div>


            <div class="row btn-manger-part">

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                    <button type="button" class="btn btn-md custom_btn_primary pull-right"
                        (click)="gotoStepWizard(true)"> Suivant </button>

                    <!-- <button type="button" class="btn btn-md btn-cancel pull-right" (click)="modalRef.hide()">Annuler</button> -->
                </div>

            </div>

        </ng-container>


        <ng-container *ngIf="stepWizard == 2">

            <div class="row">

                <div class="col-md-4 col-sm-12 mb-3">
                    <label>Ville de résid. :
                    </label>
                    <input class="form-control custom-input" placeholder="Ville de résidence" autocomplete="off"
                        [(ngModel)]="itemToSave.villeNais" name="villeNais" type="text">
                </div>


                <div class="col-md-4 col-sm-12 mb-3">
                    <label>Quartier de résid. :
                    </label>
                    <input class="form-control custom-input" placeholder="Quartier de résid." autocomplete="off"
                        [(ngModel)]="itemToSave.quartierResidence" name="quartierResidence" type="text">
                </div>

                <div class="col-md-4 col-sm-12 mb-3">
                    <label>Village :
                    </label>
                    <input class="form-control custom-input" placeholder="Village" autocomplete="off"
                        [(ngModel)]="itemToSave.village" name="village" type="text">
                </div>

            </div>

            <div class="row">
                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Pièce :
                    </label>
                    <input class="form-control custom-input" placeholder="N° Pièce" autocomplete="off"
                        [(ngModel)]="itemToSave.numeroPiece" name="numeroPiece" type="text">
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Date établissement :
                    </label>
                    <input class="form-control custom-input" placeholder="Date établissement" autocomplete="off"
                        [(ngModel)]="itemToSave.dateEtablissementPiece" name="dateEtablissementPiece" type="text"
                        bsDatepicker [maxDate]="dateActuelle"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' }">
                </div>


                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Date expiration :
                    </label>

                    <input type="text" placeholder="Date expiration" class="form-control" name="dateExpiration"
                        [(ngModel)]="itemToSave.dateExpirationPiece" bsDatepicker [minDate]="dateActuelle"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-dark-blue' }">

                    <!-- <input class="form-control custom-input" placeholder="Date expiration" autocomplete="off"
                        [(ngModel)]="itemToSave.dateExpirationPiece" name="dateExpirationPiece" type="text"> -->
                </div>

                <div class="col-md-3 col-sm-12 mb-3">
                    <label>Centre des impôt(s) :
                    </label>

                    <!-- <select class="form-control custom_input" id="centreDesImpotsSelected"
                        [(ngModel)]="itemToSave.centreDesImpotsSelected" name="centreDesImpotsSelected">
                        <option value="">-- Choisissez --</option>
                        <option *ngFor="let item of listCentreImports" [ngValue]="item">
                            {{ item.libelle }}
                        </option>
                    </select> -->

                    <ng-select [(ngModel)]="itemToSave.centreDesImpotsSelected" placeholder="Choisissez"
                    name="centreDesImpotsSelected" id="centreDesImpotsSelected" [multiple]="false" [closeOnSelect]="true">
                    <ng-option *ngFor="let item of listCentreImports" [value]="item">
                        {{ item?.libelle }}
                    </ng-option>
                </ng-select>

                    <!-- <input class="form-control custom-input" placeholder="Libellé" autocomplete="off"
                        [(ngModel)]="itemToSave.libelle" name="libelle" type="text"> -->
                </div>


            </div>


            <div class="row btn-manger-part">

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                    <button type="button" class="btn btn-md custom_btn_primary pull-right"
                        (click)="gotoStepWizard(true)"> Suivant </button>

                    <button type="button" class="btn btn-md btn-cancel pull-right"
                        (click)="gotoStepWizard(false)">Précédent</button>
                </div>

            </div>

        </ng-container>


        <ng-container *ngIf="stepWizard == 3">

            <div class="row">
                <div class="col-md-6 col-sm-12 mb-3">
                    <label>Personne à contacter :<span class="required_champs">*</span>
                    </label>
                    <input class="form-control custom-input" placeholder="Personne à contacter" autocomplete="off"
                        [(ngModel)]="itemToSave.identitePersonneAPrevenir" name="identitePersonneAPrevenir" type="text">
                </div>

                <div class="col-md-6 col-sm-12 mb-3">
                    <label>Télephone pers. à contacter :<span class="required_champs">*</span>
                    </label>
                    <input class="form-control custom-input" placeholder="Télephone" autocomplete="off"
                        [(ngModel)]="itemToSave.contactPersonneAPrevenir" name="contactPersonneAPrevenir"
                        mask="00 00 00 00 00" type="text">
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-12 mb-3">
                    <label>Adresse postale :
                    </label>
                    <input class="form-control custom-input" placeholder="Aresse postale" autocomplete="off"
                        [(ngModel)]="itemToSave.adressePostal" name="adressePostal" type="text">
                </div>

                <div class="col-md-4 col-sm-12 mb-3">
                    <label>Télephone bureau :
                    </label>
                    <input class="form-control custom-input" placeholder="Télephone bureau" autocomplete="off"
                        [(ngModel)]="itemToSave.telephoneBureau" name="telephoneBureau" mask="00 00 00 00 00"
                        type="text">
                </div>


                <div class="col-md-4 col-sm-12 mb-3">
                    <label>Télephone domicile :
                    </label>
                    <input class="form-control custom-input" placeholder="Télephone" autocomplete="off"
                        [(ngModel)]="itemToSave.telephoneDomicile" name="telephoneDomicile" mask="00 00 00 00 00"
                        type="text">
                </div>

            </div>


            <div class="row btn-manger-part">

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                    <button type="button" class="btn btn-md custom_btn_primary pull-right"
                        (click)="confirmSaveItem(itemToSave)"> Enregistrer </button>

                    <button type="button" class="btn btn-md btn-cancel pull-right"
                        (click)="gotoStepWizard(false)">Précédent</button>
                </div>

            </div>
        </ng-container>
    </form>
</ng-container>